export default {
  COUNTDOWN_INITIALIZED: 'countdowninitialized',
  LIVEPROGRAM_CHANGED: 'liveprogram-changed',
  LIVEPROGRAM_CHANGE_IMMINENT: 'liveprogram-change-imminent',
  EPG_UPDATED: 'epg-updated',
  TRACK_GA_EVENT: 'track-ga-event',
  PLAYBACK_ENDED: 'playback-ended',
  SKIP_INTRO_CLICKED: 'skip-intro-clicked',
  SKIP_CREDITS_CLICKED: 'skip-credits-clicked'
};
