import { getTimeZoneOffset } from './timeZone';
import { LudoEvents } from '@nrk/ludo-core';
import logger from 'bows';

type Player = import('../../ludo/interfaces').ExtendedLudo;

const MINUTE = 60 * 1000;
const DAY = 24 * 60 * MINUTE;

const log = logger('nielsen:day');

export const DAY_CHANGED = 'nielsen-day-changed';

export class DayTracker {
  private player: Player;
  private tracking = false;
  private startOfDay?: number;
  private timeoutId?: number;

  constructor(player: Player) {
    this.player = player;

    player.on(LudoEvents.ITEM_CHANGED, this.handleItemChanged, this);
    this.handleItemChanged();
  }

  private startTracking() {
    this.player.on(LudoEvents.PLAYING, this.check, this);
    this.player.on(LudoEvents.PAUSE, this.clearTimeout, this);
    this.player.on(LudoEvents.SEEKED, this.check, this);
  }

  private stopTracking() {
    this.player.off(LudoEvents.PLAYING, this.check, this);
    this.player.off(LudoEvents.PAUSE, this.clearTimeout, this);
    this.player.off(LudoEvents.SEEKED, this.check, this);
    this.clearTimeout();
  }

  private handleItemChanged() {
    const mediaItem = this.player.current();
    if (mediaItem && mediaItem.isLive) {
      if (!this.tracking) {
        this.startTracking();
        this.tracking = true;
      }
    } else {
      if (this.tracking) {
        this.stopTracking();
        this.tracking = false;
      }
    }
  }

  private clearTimeout() {
    if (this.timeoutId) {
      window.clearTimeout(this.timeoutId);
      this.timeoutId = 0;
    }
  }

  private check() {
    this.clearTimeout();

    const currentLiveTime = this.player.currentLiveTime();
    const time = currentLiveTime.getTime();
    const timeZoneOffset = getTimeZoneOffset(time) * MINUTE;
    const adjTime = time + timeZoneOffset;
    const startOfDay = adjTime - adjTime % DAY;

    if (!this.player.isPaused()) {
      const nextCheck = Math.max(100, (startOfDay + DAY - adjTime) / 2);
      log(`Next check in ${nextCheck / 1000} seconds.`);
      this.timeoutId = window.setTimeout(this.check.bind(this), nextCheck);
    }

    if (startOfDay !== this.startOfDay) {
      if (this.startOfDay !== undefined) {
        log('Day changed in Norway:', currentLiveTime);
        this.player.emit(DAY_CHANGED, {
          startOfDayUTC: startOfDay - timeZoneOffset,
          startOfDayLocal: startOfDay,
          tzOffset: timeZoneOffset
        });
      }
      this.startOfDay = startOfDay;
    }
  }
}
