/**
 * Loader for /playback/statistics/nielsen/channel/{channel}?plannedStart={plannedStart}
 */

import { APIClient, APILoader, APIResult } from '../types';
import { UnexpectedStatusError, DataError } from '../errors';

type NielsenLiveProgramStats = import('./response/NielsenStatistics').NielsenLiveProgramStats;

export class NielsenLiveProgramStatsLoader implements APILoader<NielsenLiveProgramStats> {
  private client: APIClient;
  private path: string;

  constructor(client: APIClient, channelId: string) {
    this.client = client;
    this.path = `/playback/statistics/nielsen/channel/${channelId}`;
  }

  load(plannedStart: string): Promise<APIResult<NielsenLiveProgramStats>> {
    return this.client.fetch<NielsenLiveProgramStats>(this.path, { plannedStart }, this.processResponse)
      .then((data) => {
        return {
          data,
          links: undefined
        };
      });
  }

  private processResponse(response: Response): Promise<NielsenLiveProgramStats> {
    if (response.status !== 200) {
      throw new UnexpectedStatusError(response);
    }

    return response.json()
      .catch((error) => {
        throw new DataError(`Failed to parse JSON: ${error}`, response);
      });
  }
}
