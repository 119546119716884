/*
 * A generic state machine framework. It supports a hierarchy of states,
 * enabling event handler inheritance and enter and exit actions around sets of
 * states.
 *
 * There is only one current state. Returning to a superstate does not restore
 * any previous substate - you get the exact state that you specifically
 * transition to.
 *
 * Substates must be addressed with full path, except for any single top state,
 * which is automatically implicit. For example:
 *
 *   this.transition('STATE.SUBSTATE');
 *   this.transition(['STATE', 'SUBSTATE']);
 *
 * Transitions call the exit and enter actions of all the states in the
 * hierarchy that are being exited and entered. Shared superstates of the
 * source and target states are not exited and entered.
 *
 * If the source or target state is a shared state (transition to same state or
 * up/down the state hierarchy), and the transition is flagged as external,
 * then the shared source or target state is exited and entered too (but not
 * any of the superstates).
 *
 * For example, transitioning to the current/same state does nothing, except
 * emitting the 'transition' event:
 *
 *   this.transition(this.state);
 *
 * With an external transition, the exit and enter actions are called on the
 * (leaf) state:
 *
 *   this.transition(this.state, { external: true });
 *
 * The same applies to the shared source or target state when transitioning up
 * or down the state hierachy.
 *
 * Configure by creating an object hierarchy of state definitions:
 *
 *     stateName: {
 *       enter() {},
 *       exit() {},
 *       events: {}, // Event handler functions.
 *       states: {}  // Any substates.
 *     }
 *
 * and feed it to the StateMachine constructor:
 *
 *     interface MyFSM extends StateMachine<MyFSM> {
 *        ...machine event functions.
 *     }
 *     const m = new StateMachine<MyFSM>(config) as MyFSM;
 *
 * The event handlers become available as instance methods
 * (instance/this).handlerName()), already bound to 'this' so that they are
 * safe to use as event listeners.
 */

import EventEmitter from 'eventemitter3';

type State = string[];
type StateAddr = string | string[];
interface StateConfigMap<M> { [name: string]: StateConfig<M>; }
type EventFunc<M> = (this: M, ...args: any[]) => any;

interface EventAPI<M> {
  [key: string]: EventFunc<M>;
}

interface StateConfig<M> {
  enter?: (this: M) => void;
  exit?: (this: M) => void;
  events?: EventAPI<M>;
  states?: StateConfigMap<M>;
}

// Used internally for storing the names of the states.
interface NamedStateConfig<M> extends StateConfig<M> {
  name: string;
}

export class StateMachine<M> extends EventEmitter {
  private implicitRootStateName: string | null;
  state: State;

  constructor(private config: StateConfigMap<M>, { initialState = null } = {}) {
    super();

    // Construct a single API from all the state events.
    const eventApi = {};
    this.process(eventApi, this.config);
    Object.assign(this, eventApi);

    // Resolve any single root state.
    const rootNames = Object.keys(config);
    this.implicitRootStateName = rootNames.length === 1 ? rootNames[0] : null;

    // Set initial state.
    this.state = this.toValidState(initialState || [], 'initial');
  }

  /*
   * Extract all events and assemble an API.
   */
  private process(api: EventAPI<M>, states: StateConfigMap<M>) {
    Object.keys(states).forEach((stateName) => {
      const state = states[stateName];

      (state as NamedStateConfig<M>).name = stateName;

      if (state.events) {
        Object.keys(state.events).forEach((eventName) => {
          api[eventName] = api[eventName] || this.triggerFor(eventName);
        });
      }

      if (state.states) {
        this.process(api, state.states);
      }
    });
  }

  /*
   * Return a valid state array or throws an exception. The address can be an
   * array of strings or a string with states separated by dots. An implicit
   * root state does not need to be specified.
   */
  private toValidState(addr: StateAddr, contextHint?: string): State | never {
    let path: State;

    if (Array.isArray(addr)) {
      path = addr.slice(0); // Copy
    } else {
      path = addr.split('.');
    }

    // Implicit single root?
    const implicitRoot = this.implicitRootStateName;
    if (implicitRoot && (path.length === 0 || path[0] !== implicitRoot)) {
      path.unshift(implicitRoot);
    }

    // Validate.
    if (path.length === 0) {
      throw new Error(contextHint ? `No ${contextHint} state specified.` : 'No state specified');
    }

    return path;
  }

  /*
   * Return all the state configurations for a state path. If a path does not
   * match actual states, then an exceptions is thrown.
   */
  private stateConfigs(path: State): StateConfig<M>[] | never {
    let nextLevel: StateConfigMap<M> | undefined = this.config;

    return path.map((name) => {
      if (!nextLevel || !(name in nextLevel)) {
        throw new Error(`State ${name} in ${path.join('.')} not found.`);
      }
      const state = nextLevel[name];
      nextLevel = state && state.states;
      return state;
    });
  }

  /*
   * Transition from a state to another.
   */
  transition(addr: StateAddr, { external = false } = {}) {
    const fromPath = this.state.slice(0);
    const toPath = this.toValidState(addr, 'transition');

    // Find the state path index that excludes all the parent states that
    // should not be exited and entered again.
    const keepCommon = external ? 1 : 0;
    let commonParents = 0;
    while (commonParents + keepCommon < fromPath.length &&
      commonParents + keepCommon < toPath.length) {
      if (fromPath[commonParents] !== toPath[commonParents]) {
        break;
      }
      commonParents++;
    }

    // Exit
    const exitStates = this.stateConfigs(fromPath).slice(commonParents);
    exitStates.reverse().forEach((state) => {
      if (state.exit) {
        state.exit.call(this as any);
      }
      this.state.pop();
    });

    // Enter
    const enterStates = this.stateConfigs(toPath).slice(commonParents);
    enterStates.forEach((state) => {
      const name = (state as NamedStateConfig<M>).name;
      this.state.push(name);
      if (state.enter) {
        state.enter.call(this as any);
      }
    });

    this.emit('transition', {
      from: fromPath.join('.'),
      fromPath,
      to: toPath.join('.'),
      toPath
    });
  }

  private trigger(eventName: string, ...args: any[]): any {
    const states = this.stateConfigs(this.state);
    let i = states.length;

    while (--i >= 0) {
      const state = states[i];
      if (state.events && eventName in state.events) {
        const prop = state.events[eventName];
        return prop.call(this as any, ...args);
      }
    }

    this.emit('noHandler', {
      name: eventName,
      state: this.fullState,
      statePath: this.state,
      args
    });

    return null;
  }

  private triggerFor(eventName: string): (...args: any[]) => any {
    return (...args) => {
      return this.trigger(eventName, ...args);
    };
  }

  get stateName(): string {
    return this.state[this.state.length - 1];
  }

  get fullState(): string {
    return this.state.join('.');
  }
}
