/**
 * Loader for /podcasts/{podcast}[/episodes/{episode}].
 */

import { PathLoader } from '../PathLoader';
import { APIClient, APIResult, ResponseProcessor } from '../types';

type Podcast = import('./response/PodcastEpisode').Podcast;
type PodcastEpisode = import('./response/PodcastEpisode').PodcastEpisode;

export class PodcastEpisodeLoader extends PathLoader<PodcastEpisode | null> {
  protected processResponse: ResponseProcessor<PodcastEpisode | null>;

  constructor(client: APIClient, podcast: string, episode: string | number = 0) {
    if (typeof episode === 'number') {
      super(client, `/podcasts/${podcast}`);
      this.processResponse = this.processPodcast(episode);
    } else {
      super(client, `/podcasts/${podcast}/episodes/${episode}`);
      this.processResponse = this.processEpisode;
    }
  }

  load(): Promise<APIResult<PodcastEpisode>> {
    return super.load()
      .then((result) => {
        if (result.data === null) {
          throw new Error('No such podcast episode');
        }
        return result as APIResult<PodcastEpisode>;
      });
  }

  private processEpisode(response: Response) {
    return super.processResponse(response);
  }

  private processPodcast(index: number): ResponseProcessor<PodcastEpisode | null> {
    return (response) => {
      return super.processResponse(response)
        .then((data) => {
          const podcast = data as unknown as Podcast;
          const episodes = podcast._embedded && podcast._embedded.episodes && podcast._embedded.episodes.items;

          if (!episodes || index < 0 || index >= episodes.length) {
            return null;
          }

          // For when podcasts are played in a video player.
          const episode = episodes[index];
          if (!episode.imageUrl || !episode.imageUrl.length) {
            episode.imageUrl = podcast.imageUrl;
          }

          return episode;
        });
    };
  }
}
