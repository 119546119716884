import { LudoEvents } from '@nrk/ludo-core';
import DIMENSIONS from './dimensions';
import { Tracker } from './tracker';
import { ExtendedLudo } from '../../ludo/interfaces';

type Fields = UniversalAnalytics.FieldsObject;

export default (tracker: Tracker, player: ExtendedLudo): void => {

  let latestEvent = '';

  [
    LudoEvents.LOADING,
    LudoEvents.LOAD,
    LudoEvents.LOADED,
    LudoEvents.ENDED,
    LudoEvents.SEEKING,
    LudoEvents.SEEKED,
    LudoEvents.PLAY,
    LudoEvents.PLAYING,
    LudoEvents.PAUSE,
    LudoEvents.ADAPTER,
    LudoEvents.PLAYER,
    LudoEvents.BEFOREPLAY,
    LudoEvents.PREPARE,
    LudoEvents.PREPARED,
    LudoEvents.CUE,
    LudoEvents.CUED,
    LudoEvents.READY,
    LudoEvents.ITEM_CHANGED,
    LudoEvents.BUFFERSTART,
    LudoEvents.BUFFEREND,
    LudoEvents.LOADEDDATA
  ].forEach((event) => {
    player.on(event, () => {
      if (latestEvent === event) {
        return;
      }
      tracker.set({
        [DIMENSIONS.latestEvent]: event
      });
      latestEvent = event;
    });
  });

  const eventMap = {
    [LudoEvents.PREPARE]: DIMENSIONS.reachedPrepare,
    [LudoEvents.PREPARED]: DIMENSIONS.reachedPrepared,
    [LudoEvents.LOAD]: DIMENSIONS.reachedLoad,
    [LudoEvents.LOADED]: DIMENSIONS.reachedLoaded,
    [LudoEvents.PLAY]: DIMENSIONS.reachedPlay,
    [LudoEvents.PLAYING]: DIMENSIONS.reachedPlaying
  };

  const initial = Object.keys(eventMap)
    .map((event) => eventMap[event])
    .reduce((o, dimension) => {
      // @ts-ignore
      o[dimension] = '0';
      return o;
    }, {} as Fields);

  tracker.set(initial);

  Object.keys(eventMap).forEach((event) => {
    player.once(event, () => {
      tracker.set({
        [eventMap[event]]: '1'
      });
    });
  });

};
