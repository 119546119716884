/*
 * A finite state machine that takes events from player and the browser, and
 * invokes the appropriate Nielsen tracker methods accordingly.
 */
import { LudoEvents } from '@nrk/ludo-core';
import { StateMachine } from './StateMachine';
import { NielsenTracker } from './NielsenTracker';
import { Events as EPGEvents } from './LiveEvents';

type ProgramEventData = import('./LiveEvents').ProgramEventData;
type ExtendedLudo = import('../../ludo/interfaces').ExtendedLudo;

interface NielsenFSM extends StateMachine<NielsenFSM> {
  adapterChanged: () => void;
  browserClosing: () => void;
  destroy: () => void;
  durationChanged: (duration: number) => void;
  epgChanged: (data: ProgramEventData) => void;
  incomingMedia: () => void;
  mediaChanged: () => void;
  play: () => void;
  pause: () => void;
  ended: () => void;
  reset: () => void;
  start: () => void;
  timeUpdate: (time: number) => void;
}

export function nielsenStateMachine(player: ExtendedLudo, tracker: NielsenTracker): NielsenFSM {

  function attachControlEvents(machine: NielsenFSM) {
    player.on(LudoEvents.LOADED, machine.incomingMedia);
    player.on(LudoEvents.ITEM_CHANGED, machine.mediaChanged);
    player.on(LudoEvents.ADAPTER, machine.adapterChanged);
    window.addEventListener('pagehide', machine.browserClosing);
    window.addEventListener('beforeunload', machine.browserClosing);
  }

  function detachControlEvents(machine: NielsenFSM) {
    window.removeEventListener('beforeunload', machine.browserClosing);
    window.removeEventListener('pagehide', machine.browserClosing);
    player.off(LudoEvents.ADAPTER, machine.adapterChanged);
    player.off(LudoEvents.ITEM_CHANGED, machine.mediaChanged);
    player.off(LudoEvents.LOADED, machine.incomingMedia);
  }

  function attachSessionEvents(machine: NielsenFSM) {
    player.on(LudoEvents.PLAYING, machine.play);
    player.on(LudoEvents.TIMEUPDATE, machine.timeUpdate);
    player.on(LudoEvents.PAUSE, machine.pause);
    player.on(LudoEvents.ENDED, machine.ended);
    player.on(EPGEvents.LIVE_PROGRAM_CHANGED, machine.epgChanged);
    player.on(EPGEvents.LIVE_PROGRAM_UPDATED, machine.epgChanged);
  }

  function detachSessionEvents(machine: NielsenFSM) {
    player.off(EPGEvents.LIVE_PROGRAM_UPDATED, machine.epgChanged);
    player.off(EPGEvents.LIVE_PROGRAM_CHANGED, machine.epgChanged);
    player.off(LudoEvents.ENDED, machine.ended);
    player.off(LudoEvents.PAUSE, machine.pause);
    player.off(LudoEvents.TIMEUPDATE, machine.timeUpdate);
    player.off(LudoEvents.PLAYING, machine.play);
  }

  return <NielsenFSM>new StateMachine<NielsenFSM>({
    NIELSEN: { // Implicit root state. Also initial state.
      events: {
        reset() { // Special handling of this in PLAYING and STOPPED.
          this.transition('IDLE');
        },
        mediaChanged() {
          this.reset();
        },
        adapterChanged() {
          this.reset();
        },
        browserClosing() {
          this.reset();
        },

        start() {
          this.reset();
          attachControlEvents(this);
        },
        destroy() {
          this.reset();
          detachControlEvents(this);
        }
      },
      states: {
        IDLE: {
          events: {
            incomingMedia() {
              // Async! Possible race conditions.
              // tslint:disable-next-line:no-floating-promises
              tracker.prepareMetadata()
                .then((prepared) => {
                  if (prepared) {
                    // For on-demand streams, we need to make sure duration is
                    // ready. The HTML5 adapter doesn't always have the
                    // duration ready.
                    this.transition(player.current()!.isLive || player.duration()
                      ? 'SESSION.START' : 'DURATION_NEEDED');
                  }
                });
            },
            reset() { } // No need to transition to same.
          }
        },
        DURATION_NEEDED: {
          enter() {
            player.on(LudoEvents.DURATIONCHANGE, this.durationChanged);
          },
          exit() {
            player.off(LudoEvents.DURATIONCHANGE, this.durationChanged);
          },
          events: {
            durationChanged(duration) {
              if (duration) {
                this.transition('SESSION.START');
              }
            }
          }
        },
        SESSION: {
          enter() {
            tracker.beginSession();
            attachSessionEvents(this);
          },
          exit() {
            detachSessionEvents(this);
            tracker.endSession();
          },
          events: {
            epgChanged(data) {
              tracker.epgChanged(data);
            }
          },
          states: {
            START: {
              events: {
                // Just waiting for the playing event isn't working in all
                // browsers. Need to wait for running time.
                timeUpdate(time) {
                  if (time && !player.isPaused()) {
                    this.transition('SESSION.PLAYING');
                  }
                }
              }
            },
            PLAYING: {
              enter() {
                tracker.loadMetadata();
              },
              events: {
                timeUpdate(time) {
                  tracker.setPlayheadPosition(time);
                },
                pause() {
                  this.transition('SESSION.STOPPED');
                },
                ended() {
                  this.transition('SESSION.STOPPED');
                },
                epgChanged(data) {
                  tracker.epgChanged(data);

                  // Reload metadata. This should ideally be an atomic operation.
                  this.transition('SESSION.STOPPED');
                  this.play();
                },
                reset() {
                  this.transition('SESSION.ENDED');
                  this.transition('IDLE');
                }
              }
            },
            STOPPED: {
              enter() {
                tracker.stop();
              },
              events: {
                play() {
                  this.transition('SESSION.PLAYING');
                },
                reset() {
                  this.transition('SESSION.ENDED');
                  this.transition('IDLE');
                }
              }
            },
            ENDED: {
              enter() {
                tracker.end();
              }
            }
          }
        }
      }
    }
  });
}
