import { LudoEvents, storage } from '@nrk/ludo-core';
import importedCoreToggle from '@nrk/core-toggle';
import importedControlBarButton from './controlBarButton';
import uiEvents from '../../ui/events';
import importedOverlayMenu from './overlayMenu';
import { getStreamState } from './dataMappers';
import PersistentStoreKeys from '../../../ludo/PersistentStoreKeys';
import { classToggle } from '../../dom';
import { ExtendedLudo } from '../../../ludo/interfaces';
import NrkEvents from '../../../nrk/NrkEvents';
import bows from 'bows';
import { LudoUIType } from '../../ui/LudoUI';
import { PreferredStreamUpdateDetail, PublicEvents } from '../../../publicEventsApi';
import arrayFind from 'array-find';
import { MediaItem, Subtitle } from '../../../nrk/mediaItem/MediaItem';
import { disableSubtitles, updatePreferredSubtitle } from '../../../nrk/subtitles/preferences';

const log = bows('ludo:a-bility');

interface CoreToggleDetails {
  willOpen: boolean;
  isOpen: boolean;
}
// add overload for toggle event to avoid type errors
declare global {
  interface HTMLElementEventMap {
    toggle: CustomEvent<CoreToggleDetails>;
  }
}

export interface AccessibilityButtonContainer {
  coreToggle?: typeof importedCoreToggle;
  controlBarButton?: typeof importedControlBarButton;
  overlayMenu?: typeof importedOverlayMenu;
}

export default (ui: LudoUIType, container: AccessibilityButtonContainer = {}) => {
  const {
    coreToggle = importedCoreToggle,
    controlBarButton = importedControlBarButton,
    overlayMenu = importedOverlayMenu
  } = container;

  require('./accessibilityOverlay.styl');

  // Scroll content to visually indicate that there is more content below
  function scrollContent(element: HTMLElement) {
    // Array.from is not supported in IE. This feature is mostly relevant
    // for mobile anyway, so just skipping if it is not supported.
    if (!Array.from || !window.requestAnimationFrame) {
      return;
    }

    window.requestAnimationFrame(() => {
      const icons = Array.from(element.querySelectorAll('svg'));
      const ludoHeight = ui.element.offsetHeight;

      const buttonToScroll = arrayFind(icons, (button: SVGElement) => {
        const bottom = button.getBoundingClientRect().bottom;
        return bottom > ludoHeight;
      });

      if (buttonToScroll) {
        const scrollWrapper = element.querySelector('.stream-options--scroll-wrapper');
        const bottom = buttonToScroll.getBoundingClientRect().bottom;

        scrollWrapper!.scrollTo(0, bottom - ludoHeight - 5);
      }
    });
  }

  return (player: ExtendedLudo) => {
    // create and set up UI
    const button = controlBarButton(ui) as ReturnType<typeof importedControlBarButton>;
    const overlay = overlayMenu(ui, player) as ReturnType<typeof importedOverlayMenu>;
    const wrapper = ui.element.querySelector<HTMLButtonElement>('.ludo-bar__button_wrapper__subtitles')!;

    wrapper.appendChild(button.element);
    wrapper.appendChild(overlay.element);
    coreToggle(button.element, {popup: 'Undertekst og tilgjengelighet'});

    // block UI-hiding when overlay is open
    button.element.addEventListener('toggle', (event) => {
      const willOpen = !event.detail.isOpen && event.detail.willOpen;
      ui.emit( willOpen ? uiEvents.BLOCKHIDECONTROLS : uiEvents.ALLOWHIDECONTROLS);
      classToggle(ui.element, 'ludo--overlay-open', willOpen);
      // pause video when opening on small screens, since overlay covers video/audio
      if (willOpen && ui.element.classList.contains('ludo--small')) {
        player.pause();

        scrollContent(overlay.element);
      }
      if (willOpen) {
        sendGaEvent({
          eventCategory: 'media-accessability',
          eventAction: 'media-accessability-button',
          eventLabel: 'open'
        });
      }
    });

    // handle user selection in overlay menu
    overlay.onStreamSelected(({detail}) => {
      const {subtitles, isSubtitlesOn, streams} = getStreamState(player);
      storage.persistent.setItem(PersistentStoreKeys.PREFERRED_MANIFEST, detail.manifestName);

      let synstolk = false;
      let tegntolk = false;

      if (detail.manifestName === 'synstolk') {
        synstolk = true;
      } else if (detail.manifestName === 'tegntolk') {
        tegntolk = true;
      }

      const updateDetail: PreferredStreamUpdateDetail = { synstolk, tegntolk };
      player.emit(PublicEvents.PREFERRED_STREAM_UPDATE, updateDetail);

      const current = player.current();
      const id = current && current.id;
      const startTime = player.currentTime();
      player.replaceCurrentAndPlay({ id, synstolk, tegntolk, startTime }); // tslint:disable-line
      overlay.renderOverlayMenu({subtitles, isSubtitlesOn, streams, manifestName: detail.manifestName});
      sendGaEvent({
        eventCategory: 'media-accessability',
        eventAction: 'media-accessability-stream',
        eventLabel: detail.manifestName
      });
    });

    overlay.onSubtitleSelected(({detail}) => {
      const availableSubtitles = player && (player.current() as MediaItem).subtitles;

      const splitKey = detail.subtitleId.split('_');
      let sLang: string | undefined;
      let sType: string | undefined;
      if (splitKey.length > 2) {
        sLang = splitKey[1];
        sType = splitKey[2];
      }

      if (detail.enabled) {
        const currentSubtitles = arrayFind(availableSubtitles, (s: Subtitle) => {
          return s.srclang === sLang && s.type === sType;
        });

        if (currentSubtitles) {
          updatePreferredSubtitle(currentSubtitles, availableSubtitles);
          player.set('currentSubtitles', { lang: currentSubtitles.srclang, type: currentSubtitles.type });
        }
      } else {
        disableSubtitles(availableSubtitles);
      }

      player.subtitles(detail.enabled);

      sendGaEvent({
        eventCategory: 'media-accessability',
        eventAction: 'media-accessability-subtitles',
        eventLabel: detail.enabled ? `${sLang}_${sType}` : 'off'
      });
    });

    function onSubtitleStateChange() {
      const streamState = getStreamState(player);
      button.setButtonState(streamState);
      overlay.renderOverlayMenu(streamState);
    }

    // update state on events
    // TODO: Add event for changing subtitles?
    [
      LudoEvents.SUBTITLESON,
      LudoEvents.SUBTITLESOFF,
      LudoEvents.SUBTITLESDISABLED,
      LudoEvents.LOADED,
      LudoEvents.OPTIONCHANGED
    ].forEach((event) => player.on(event, onSubtitleStateChange));
    player.on(LudoEvents.LOADEDDATA, () => overlay.renderOverlayMenu(getStreamState(player)));

    // Handle ludo resized
    ui.on(uiEvents.PLAYER_SIZE_CHANGED, () => overlay.onLudoResized(ui.element));
    // Trigger initial size check (need player.element() to be available)
    overlay.onLudoResized(ui.element);

    // if data already available - render right away
    if (player.current()) {
      onSubtitleStateChange();
    }

    function sendGaEvent(fields: UniversalAnalytics.FieldsObject) {
      log(fields);
      player.emit(NrkEvents.TRACK_GA_EVENT, fields);
    }
  };
};
