import { LudoEvents } from '@nrk/ludo-core';
import METRICS from './metrics';
import { Tracker } from './tracker';
import { ExtendedLudo } from '../../ludo/interfaces';

type Fields = UniversalAnalytics.FieldsObject;

// There should be a better place for this:
type SendTiming = (category: string, variable: string, value: number, label?: string, fields?: Fields) => void;

export default (tracker: Tracker, player: ExtendedLudo, sendTiming: SendTiming) => {
  const trackStartTime = Date.now();

  player.once(LudoEvents.PREPARED, () => {
    const prepareTime = Date.now() - trackStartTime;

    tracker.set({
      [METRICS.prepareTime]: prepareTime
    });

    sendTiming('start', 'prepared', prepareTime);
  });

  player.once(LudoEvents.PLAY, () => {
    const playStartTime = Date.now();

    player.once(LudoEvents.PLAYING, () => {
      const startPlayingTime = Date.now() - playStartTime;

      tracker.set({
        [METRICS.startPlayingTime]: startPlayingTime
      });

      sendTiming('start', 'playing', startPlayingTime);
    });
  });
};
