import bows from 'bows';
import createAudienceTracker, { GoogleAnalyticsFields } from './tracker';
import importedNrkMediaLogger from './nrkMediaLogger';
import NrkEvents from '../NrkEvents';
import { ExtendedLudo, AnalyticsConfig } from '../../ludo/interfaces';
import { trackPlayed as _trackPlayed } from './trackPlayed';

export default function AudienceTracker(analyticsConfig: AnalyticsConfig, container: any = {}) {
  const {
    debug = bows,
    nrkMediaLogger = importedNrkMediaLogger,
    trackPlayed = _trackPlayed
  } = container;

  return (player: ExtendedLudo) => {
    const { trackingId, enabled } = analyticsConfig.ga;

    if (enabled && !trackingId) {
      // tslint:disable-next-line:no-console
      console.error('Ludo was instantiated without a required ga.trackingId.');
    }

    const tracker: ReturnType<typeof createAudienceTracker> = enabled && trackingId ?
      createAudienceTracker(player, analyticsConfig, container) :
      createNullTracker();

    player.on(NrkEvents.TRACK_GA_EVENT, (data: GoogleAnalyticsFields) => tracker.send('event', data));

    nrkMediaLogger(player, {
      tracker,
      debug
    });

    trackPlayed(player, tracker);
  };
}

function createNullTracker(): ReturnType<typeof createAudienceTracker> {
  return {
    getFields: () => (<any>{}),
    send(hitType: string, hitFields: UniversalAnalytics.FieldsObject) {}
  };
}
