import { ExtendedLudo } from '../../ludo/interfaces';

export enum Method {
  GET = 'GET',
  POST = 'POST'
}

export interface ApplicationInsightsProperties {
  [key: string]: string | undefined;
}

export interface ApplicationInsightsMeasurements {
  [key: string]: number | undefined;
}

export interface ErrorProperties extends ApplicationInsightsProperties {
  errorType?: string;
  errorReason?: string;
  errorURL?: string;
  retryCount?: string;
}

export interface AppInsightsTracker {
  sendEvent(name: string, properties?: ApplicationInsightsProperties, measurements?: ApplicationInsightsMeasurements): void;
  trackException(error: Error, properties?: ApplicationInsightsProperties, measurements?: ApplicationInsightsMeasurements, fatal?: boolean): void;
  trackDependency(method: Method, absoluteUrl: string, totalTime: number, success: boolean, resultCode: number, properties?: ApplicationInsightsProperties, measurements?: ApplicationInsightsMeasurements): void;
  trackPlayer(player: ExtendedLudo): void;
}
