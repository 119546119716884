/**
 * Loader for /playback/manifest/{type}/{id}.
 */

import arrayFind from 'array-find';
import { PathLoader } from '../PathLoader';
import { NielsenStatisticsLoader } from './NielsenStatisticsLoader';
import { NielsenLiveProgramStatsLoader } from './NielsenLiveProgramStatsLoader';

type APIClient = import('../types').APIClient;
type PlaybackManifest = import('./response/IPlaybackManifest').IPlaybackManifest;

interface Links {
  nielsenStatistics?: NielsenStatisticsLoader;
  nielsenLiveProgramStats?: NielsenLiveProgramStatsLoader;
}

export class PlaybackManifestLoader extends PathLoader<PlaybackManifest, Links> {
  constructor(client: APIClient, path: string) {
    super(client, path);
  }

  protected processLinks(data: PlaybackManifest): Links {
    return getManifestLinks(this.client, data);
  }
}

/**
 * The link processing function is exported, so that it's possible to get the
 * related loaders without the manifest loader.
 */
export function getManifestLinks(apiClient: APIClient, data: PlaybackManifest): Links {
  const links: Links = {};
  const isChannel = data.availability && data.availability.live && data.availability.live.type === 'channel';

  if (data._links && data._links.statistics) {
    const link = arrayFind(data._links.statistics, (link) => link.name === 'nielsen');
    if (link) {
      links.nielsenStatistics = new NielsenStatisticsLoader(apiClient, link.href);

      if (isChannel) {
        links.nielsenLiveProgramStats = new NielsenLiveProgramStatsLoader(apiClient, data.id);
      }
    }
  }

  return links;
}
