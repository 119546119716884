import debug from 'bows';
import { LudoUI, poster } from '../ui';
import {
  LudoEvents,
  LudoFlashlsPlayerAdapter,
  LudoHLSPlayerAdapter,
  LudoHTML5PlayerAdapter,
  LudoPlayer,
  LudoPlayerAdapter,
  LudoPlayerLoader,
  LudoPreparator,
  PartialMediaIdentifiers,
  storage
} from '@nrk/ludo-core';

import { detectLudoFailure } from './detectFailure';

import { DEFAULT_PLAYER_TYPE, initUIComponents, mergeOptions, PLAYER_TYPES } from './configuration';

import { AudienceTracker, LiveEpg, nielsenTracker, RetryClient, Site } from '../nrk';
import { directLoader, podcastLoader } from '../nrk/mediaItem';

import chromecast from './chromecast';
import pictureInPicture from './pictureInPicture';
import posterSetup from './posterSetup';

import PersistentStoreKeys from './PersistentStoreKeys';
import applicationTracker from '../nrk/applicationTracker';
import unifedAnalyticsDimensions from '../nrk/applicationTracker/dimensions';

import { PlaybackMediaItemLoader } from '../nrk/mediaItem/playback';
import requiredMinimumPreparator from './requiredMinimumPreparator';
import { ExtendedLudo, LudoCoreOptions, LudoPlayerFeatures, LudoPlayerOptionsArgument } from './interfaces';
import { detectPlaybackEnded } from './session/detectPlaybackEnded';
import { applyPlaybackSessionHandler } from './session/applyPlaybackSessionHandler';
import { getApplicationInsightsTracker } from '../nrk/ApplicationInsightsTracker/getApplicationInsightsTracker';
import { AppInsightsTracker } from '../nrk/ApplicationInsightsTracker';
import { elementClosest } from '../ui/dom';
import { detectSkipSections } from './skip/detectSkipSections';

const log = debug('ludo:embed');
const LUDO_VERSION = process.env.version;

/**
 * Mount player into web page, given an element to mount to
 * @param {HTMLElement} targetElement Mount point
 * @param {String|Array|Object[]} programIds Program IDs
 * @param {Object} opt Player options
 * @param {Site} site
 * @param applicationInsightsTracker {AppInsightsTracker}
 * @returns {Object} player
 */
function mount(targetElement: HTMLElement, programIds: PartialMediaIdentifiers, opt: LudoPlayerOptionsArgument = {}, site: Site, applicationInsightsTracker: AppInsightsTracker) {
  const initialized = targetElement.getAttribute('data-initialized');
  if (initialized === 'true') {
    throw new Error('Ludo has already been initialized on the given target element.');
  }
  targetElement.setAttribute('data-initialized', 'true');

  const additionalUse = opt.use || [];

  const options = mergeOptions(opt);
  const {
    'pinned-control-overlay': isPinned,
    apiClient: apiClientOptions,
    playerType,
    style,
    videoPlayerAspectRatio,
    features = {} as LudoPlayerFeatures
  } = options;

  if (options.debug && log.enableBrowserConsole) {
    log.enableBrowserConsole();
  }

  // TODO: Should not be needed
  let stageElement = targetElement;
  if (playerType === PLAYER_TYPES.audio) {
    stageElement = document.createElement('div');
    while (targetElement.firstChild) {
      stageElement.appendChild(targetElement.firstChild);
    }
    targetElement.appendChild(stageElement);
  }

  stageElement.classList.add('ludo'); // IE11 does only support adding single className at the time
  stageElement.classList.add('ludo-stage');
  if (!features.disableUI && !stageElement.hasAttribute('tabindex')) {
    stageElement.tabIndex = 0;
  }

  let ui: ReturnType<typeof LudoUI> | undefined;
  if (!features.disableUI) {
    ui = LudoUI({
      targetElement,
      stageElement
    }, {
      'pinned-control-overlay': isPinned,
      version: LUDO_VERSION,
      style, // TODO From where
      aspectRatio: playerType === PLAYER_TYPES.video ? videoPlayerAspectRatio : undefined
    });
  }

  let ludoPoster: ReturnType<typeof poster>;
  let posterElement: HTMLElement | undefined;
  let initialPlayer: HTMLMediaElement | null;

  const initialPoster = stageElement.querySelector('img');
  initialPlayer = stageElement.querySelector('video,audio') as HTMLMediaElement;

  if (!initialPlayer) {
    initialPlayer = document.createElement(playerType === PLAYER_TYPES.audio ? 'audio' : 'video');
    // TODO Can be streamlined
    if (stageElement.firstChild) {
      stageElement.insertBefore(initialPlayer, stageElement.firstChild);
    } else {
      stageElement.appendChild(initialPlayer);
    }
  }

  // Make videos on iOS play inline (not fullscreen as default)
  // Ref. https://webkit.org/blog/6784/new-video-policies-for-ios/
  initialPlayer.setAttribute('playsinline', '');
  initialPlayer.setAttribute('webkit-playsinline', '');
  initialPlayer.setAttribute('aria-hidden', 'true');
  initialPlayer.muted = options.mute;
  if (!initialPlayer.hasAttribute('preload')) {
    initialPlayer.setAttribute('preload', options.preload ? 'auto' : 'none');
  }

  if (ui) {
    stageElement.addEventListener('click', (event: Event) => {
      const button = <HTMLButtonElement>elementClosest(event.target as Element, 'button[name]');
      if (button) {
        ui!.emit(`${event.type}.${button.name}`, event, button);
      }
    });
  }

  // TODO: Playlis/cue/index/next/autoadvance - when is playlist used?
  // TODO: SUPPORTS_DVR part of PS API?
  // TODO: What is options.features? (poster, nielsen, analytics, liveEpg)
  // TODO: options.style === a name like radio? so it's more like "theme"? when is it used?
  // TODO: options.config = ['video', 'audio', 'radio', 'podcast', 'simple'] when and how used?
  // TODO: what is loadMedia vs. prepareMedia in ludo-core?
  // TODO: displayModalNotification and displayNotification out of ludo/core
  // TODO: drop playlist, introduce .destory and keep/rename .cue

  if (features.poster && ui) {
    ludoPoster = poster(ui, options.poster);
    posterElement = stageElement.querySelector('.ludo-poster') as HTMLElement;
    posterSetup({ stageElement, initialPoster, initialPlayer, posterElement });
  }

  const apiDomain = apiClientOptions.domain || 'psapi.nrk.no';
  const apiClient = new RetryClient(apiDomain, applicationInsightsTracker, apiClientOptions);

  let use: ((player: ExtendedLudo) => any)[] = [];
  if (ui) {
    use = [
      ...initUIComponents(options, ui)
    ];
  }

  if (features.nielsen) {
    use.push(nielsenTracker(apiClient, { production: site.isProduction }));
  }

  if (features.analytics && options.analytics) {
    const config = options.analytics;
    config.userId = options.userId || null;
    use.push(AudienceTracker(config));
  }

  if (features.liveEpg && apiClient) {
    use.push(LiveEpg(apiClient));
  }

  // TODO: This is because pinned-control-overlay must be set after PREPARED?
  if (ui) {
    if (playerType === DEFAULT_PLAYER_TYPE) {
      use.push((player: ExtendedLudo) => player.on(LudoEvents.PREPARED, () => {
        const current = player.current();
        if (current && current.isAudio) {
          ui!.set('pinned-control-overlay', true);
        }
      }));
    }
  }

  const adapters: LudoPlayerAdapter[] = [
    // @ts-ignore
    new LudoHLSPlayerAdapter(),
    // @ts-ignore
    new LudoHTML5PlayerAdapter(),
    // @ts-ignore
    new LudoFlashlsPlayerAdapter()
  ];

  // TODO: playbackLoader vs. podcastLoader?
  const loaders: LudoPlayerLoader[] = opt.loaders ? opt.loaders : [
    podcastLoader(apiClient),
    directLoader
  ];
  const preparators: LudoPreparator[] = features.poster ? [ludoPoster!] : [requiredMinimumPreparator];

  if (ui) {
    chromecast({
      ui,
      options,
      use,
      adapters
    });
  }

  const ludoOptions: LudoCoreOptions = {
    targetElement,
    stageElement,
    posterElement,
    ...options
  };

  ludoOptions.use = [...use, ...additionalUse];

  log('Using options', ludoOptions);

  const itemLoaders = [new PlaybackMediaItemLoader(apiClient, storage.persistent)];

  const player = new LudoPlayer(loaders, preparators, adapters, ludoOptions, itemLoaders);

  const extendedPlayer = player as ExtendedLudo;

  applyPlaybackSessionHandler(extendedPlayer);
  detectPlaybackEnded(extendedPlayer);
  detectSkipSections(extendedPlayer);
  applicationInsightsTracker.trackPlayer(extendedPlayer);

  if (log.enableBrowserConsole) {
    extendedPlayer.enableBrowserConsole = log.enableBrowserConsole;
  }

  if (options.debugevents) {
    const logEvent = debug('ludo:event');
    Object.keys(LudoEvents)
      .map((event) => event as keyof typeof LudoEvents)
      .filter((event) => [LudoEvents.TIMEUPDATE].indexOf(LudoEvents[event]) === -1)
      .forEach((event) => {
        player.on(LudoEvents[event], (...args) => logEvent(event, ...args));
      });
  }

  // TODO: why BEFOREPLAY - can be earlier?
  player.once(LudoEvents.BEFOREPLAY, () => {
    const storedVolume = storage.persistent.getItem(PersistentStoreKeys.VOLUME);
    const volume = storedVolume ? parseFloat(storedVolume) : 1;
    player.setVolume(volume);
  });

  // Avoid having the previous media item playing in backgrond
  player.on(LudoEvents.CUE, () => player.unload());

  player.on(LudoEvents.PREPARED, () => {
    if (!options.preload) {
      return;
    }
    // tslint:disable-next-line:no-floating-promises
    player.preload();
  });

  if (ui) {
    extendedPlayer.ui = ui;

    if (options.playerType === PLAYER_TYPES.video) {
      pictureInPicture({ player: extendedPlayer, ui, videoElement: initialPlayer, document });
    }
  }

  player
    .cue(programIds)
    .catch((e) => {
      log.error('Cue failed:', e);
    });

  return extendedPlayer;
}

export default function catchMount(
  targetElement: HTMLElement,
  programIds: PartialMediaIdentifiers,
  options: LudoPlayerOptionsArgument, {
    onStartupFailure = (error: unknown, event: string, timespan?: number, timedout?: boolean) => { },
    onStartupSuccess = (event: string, timespan?: number, timedout?: boolean) => { }
  } = {}) {

  const playerId = Date.now().toString();
  const { referrer = document.referrer } = options;
  const site = new Site(referrer);

  const opts = {
    // ...site.options, // Not in use.
    ...options,
    playerId
  };

  const appTracker = applicationTracker(
    playerId,
    {
      appVersion: LUDO_VERSION
    }
  );

  function sendEvent(action: string, label: string, value?: number, fields?: { [key: string]: string | undefined; }) {
    appTracker.sendEvent('ludo-start', `ludo-start-${action}`, `ludo-start-${label}`, value, fields);
  }

  const applicationInsightsTracker = getApplicationInsightsTracker(playerId, site, referrer, programIds, options);

  try {
    const player = mount(targetElement, programIds, opts, site, applicationInsightsTracker);
    detectLudoFailure(player, (error, event, timespan, timedout) => {
      onStartupFailure(error, event, timespan, timedout);

      sendEvent(event, timedout ? 'timeout' : 'failure', timespan, {
        [unifedAnalyticsDimensions.errorReason]: error && error.message,
        [unifedAnalyticsDimensions.errorSeverity]: timedout ? 'warning' : 'fatal',
        [unifedAnalyticsDimensions.errorDetails]: error && error.stack
      });

      const properties: { [key: string]: string; } = {
        errorType: timedout ? 'timeout' : 'failure',
        errorSeverity: timedout ? 'warning' : 'fatal',
        errorReason: error.message
      };

      if (error.stack) {
        properties.errorDetails = error.stack;
      }

      const measurements = timespan ? { timespan } : undefined;

      applicationInsightsTracker.sendEvent(`ludo-${event}-failure`, properties, measurements);
    }, (event, timespan, timedout) => {
      onStartupSuccess(event, timespan, timedout);

      const measurements = timespan ? { timespan } : undefined;

      applicationInsightsTracker.sendEvent(`ludo-${event}-success`, undefined, measurements);
    });

    appTracker.trackPlayer(player);

    return player;
  } catch (e) {
    onStartupFailure(e, 'init');

    sendEvent('init', 'failure', 0, {
      [unifedAnalyticsDimensions.errorReason]: (e && 'message' in e) ? (e as Error).message : `${e}`,
      [unifedAnalyticsDimensions.errorSeverity]: 'fatal',
      [unifedAnalyticsDimensions.errorDetails]: e ? (e as Error).stack : undefined
    });

    applicationInsightsTracker.sendEvent('ludo-start', {
      errorReason: (e && 'message' in e) ? (e as Error).message : `${e}`,
      errorSeverity: 'fatal',
      errorDetails: e ? (e as Error).stack : undefined
    }, undefined);

    throw e;
  }
}
