import { AppInsights } from 'applicationinsights-js';

type AppInsightsConfig = typeof AppInsights.config;

export interface SiteConfig {
  hostname: string;
  pathname?: string;
  data: SiteConfigData;
}

export interface SiteConfigData {
  name?: string;
  environment?: string;
  isVideoEmbed?: boolean;
  options?: SiteOptions;
}

export interface SiteOptions {
  applicationInsights?: AppInsightsConfig;
}

export const siteConfigs: SiteConfig[] = [
  {
    hostname: '^[^\\.]*\\btv\\b[^\\.]*\\.nrk\\.no$',
    data: {
      name: 'NRK TV'
    }
  },
  {
    hostname: '^[^\\.]*\\bradio\\b[^\\.]*\\.nrk\\.no$',
    data: {
      name: 'NRK Radio'
    }
  },
  {
    hostname: '^[^\\.]*\\btv\\b[^\\.]*\\.nrksuper\\.no$',
    data: {
      name: 'NRK Super TV'
    }
  },
  {
    hostname: '^(.+\\.)?nrksuper\\.no$',
    data: {
      name: 'NRK Super'
    }
  },
  {
    hostname: '^(.+\\.)?p3\\.no$',
    data: {
      name: 'NRK P3'
    }
  },
  {
    hostname: '^(.+\\.)?nrkbeta\\.no$',
    data: {
      name: 'NRK beta'
    }
  },
  {
    hostname: '^(.+\\.)?yr\\.no$',
    data: {
      name: 'Yr'
    }
  },
  {
    hostname: '\\b(ark|felles\\.ds|preview|fil|kubeint|snutt)\\.nrk\\.no$',
    data: {
      environment: 'internal'
    }
  },
  {
    hostname: '^static\\.nrk\\.no$',
    pathname: '^/dev/',
    data: {
      environment: 'dev'
    }
  },
  {
    hostname: '(www|static)\\.nrk\\.no$',
    pathname: '^(/embed/.*|.*/video-embed(\\.html)?)$',
    data: {
      isVideoEmbed: true
    }
  },
  {
    hostname: '\\.nrk\\.no$',
    data: {
      name: 'NRK.no',
      options: {
        applicationInsights: {
          samplingPercentage: 10
        }
      }
    }
  },
  {
    hostname: '^localhost$',
    data: {
      environment: 'test'
    }
  },
  {
    // Internal domains without dots.
    hostname: '^[A-Za-z0-9]+$',
    data: {
      environment: 'internal'
    }
  },
  {
    // NRK IP ranges.
    hostname: '^160\\.6[78]\\.\\d+\\.\\d+$',
    data: {
      environment: 'internal'
    }
  },
  {
    // Assume all IP addresses are for testing.
    hostname: '^\\d+\\.\\d+\\.\\d+\\.\\d+$',
    data: {
      environment: 'test'
    }
  },
  {
    hostname: '^nrkno\\.github\\.io$',
    data: {
      environment: 'test'
    }
  }
];
