import { AppInsights } from 'applicationinsights-js';
import { ExtendedLudo, LudoPlayerOptionsArgument } from '../../ludo/interfaces';
import bows from 'bows';
import { bootstrapApplicationInsights } from './bootstrapApplicationInsights';
import { getPlayerValues } from './getPlayerValues';
import cuid from 'cuid';
import {
  Method,
  AppInsightsTracker,
  ApplicationInsightsProperties,
  ErrorProperties,
  ApplicationInsightsMeasurements
} from './index';
import { LudoEvents, PartialMediaIdentifiers } from '@nrk/ludo-core';
import { Site } from '../utilities/Site';

type LudoError = Error & {
  code: number
  url?: string;
};

interface LudoErrorData {
  fatal: boolean;
  type: string;
  reason: string;
  url?: string;
}

export class ApplicationInsightsTracker implements AppInsightsTracker {
  private readonly appInsights: typeof AppInsights;
  private readonly customDimensions: ApplicationInsightsProperties;
  private player?: ExtendedLudo;
  private site: Site;
  private trackedEvents: { [message: string]: boolean } = {};
  private log = bows('app:track');

  constructor(instrumentationKey: string, site: Site, referrer: string) {
    const options = site.isProduction ? site.options.applicationInsights || {} : {};
    this.appInsights = bootstrapApplicationInsights(instrumentationKey, options);
    this.site = site;

    this.customDimensions = {
      applicationVersion: process.env.buildVersion,
      applicationName: 'ludo',
      hostname: window.location.hostname,
      referrer,
      site: site.fullName,
      buildTime: process.env.buildTime
    };
  }

  trackPageView() {
    this.log('Track pageview');

    this.appInsights.trackPageView(
      document.title,
      window.location.href,
      filterEmptyProps(this.customDimensions)
    );
  }

  trackPlayer(player: ExtendedLudo) {
    this.player = player;

    this.trackErrors();
  }

  private trackErrors() {
    this.player?.on(LudoEvents.ERROR, this.onError.bind(this));
  }

  private onError(error: LudoError, errorData?: LudoErrorData) {
    if (!errorData || !errorData.fatal) {
      return;
    }

    const current = this.player?.current();
    if (current && !current.isPlayable) {
      return;
    }

    const errorMessageParts: string[] = [];
    if (error) {
      const errorName = error.name || error.constructor.name;
      if (errorName) {
        errorMessageParts.push(`${errorName}: `);
      }
      if (error.message) {
        errorMessageParts.push(error.message);
      }
      if (error.code) {
        errorMessageParts.push(`[code:${error.code}]`);
      }
      if (!errorMessageParts.length) {
        errorMessageParts.push(error.toString());
      }
    }

    const errorMessage = errorMessageParts.join('');

    // Filter error duplicates
    if (typeof this.trackedEvents[errorMessage] !== 'undefined') {
      return;
    }

    this.trackedEvents[errorMessage] = true;

    const errorProps: ErrorProperties = {
      errorType: errorData.type,
      errorReason: errorData.reason
    };

    if (error && error.url) {
      errorProps.errorURL = error.url;
    }

    const { props, measure } = this.getMergedStats(errorProps);

    let trackError: Error = error;
    if (!(error instanceof Error)) {
      trackError = new PlayerError(errorData.reason);
    }

    this.log('Track error', error, props, measure);

    try {
      this.appInsights.trackException(
        trackError,
        undefined,
        filterEmptyProps(props),
        filterEmptyProps(measure),
        errorData.fatal ? 3 : 2
      );
    } catch (e) {
      this.log.error(e);
    }
  }

  sendEvent(name: string, properties?: ApplicationInsightsProperties, measurements?: ApplicationInsightsMeasurements) {
    const { props, measure } = this.getMergedStats(properties, measurements);

    this.log('Track event', name, props, measure);

    try {
      this.appInsights.trackEvent(name, filterEmptyProps(props), filterEmptyProps(measure));
    } catch (e) {
      this.log.error(e);
    }
  }

  trackException(error: Error, properties?: ApplicationInsightsProperties, measurements?: ApplicationInsightsMeasurements, fatal: boolean = false) {
    const { props, measure } = this.getMergedStats(properties, measurements);

    try {
      this.appInsights.trackException(
        error,
        undefined,
        filterEmptyProps(props),
        filterEmptyProps(measure),
        fatal ? 3 : 2
      );
    } catch (e) {
      this.log.error(e);
    }
  }

  trackDependency(method: Method, absoluteUrl: string, totalTime: number, success: boolean, resultCode: number, properties?: ApplicationInsightsProperties, measurements?: ApplicationInsightsMeasurements) {

    // Don't track successful dependency requests in production, in order to reduce cost.
    if (this.site.isProduction && success) {
      return;
    }

    const pathName = absoluteUrl.replace(/^[^\/]+\/\/[^\/]+\//, '/');
    const { props, measure } = this.getMergedStats(properties, measurements);

    try {
      // Can't get hold of Microsoft's Util.newId(). Using cuid() instead.
      this.appInsights.trackDependency(cuid(), method, absoluteUrl, pathName, totalTime, success, resultCode,
        filterEmptyProps(props), filterEmptyProps(measure));
    } catch (e) {
      this.log.error(e);
    }
  }

  setProgramIds(programIds?: PartialMediaIdentifiers) {
    this.customDimensions.programIds = typeof programIds === 'string' ? programIds : JSON.stringify(programIds);
  }

  setLudoOptions(options?: LudoPlayerOptionsArgument) {
    if (!options) {
      return;
    }
    const opts = { ...options };
    delete opts.userId;
    delete opts.use;
    delete opts.playerId;
    this.customDimensions.options = JSON.stringify(opts);
  }

  private getMergedStats(properties?: {}, measurements?: {}) {
    if (!this.player) {
      throw new Error('No player object.');
    }
    const [playerProps, playerMeasurements] = getPlayerValues(this.player);

    const player = Object.keys(playerProps).length ? JSON.stringify(playerProps) : undefined;
    const props = {
      ...this.customDimensions,
      player,
      ...properties
    };

    const measure = {
      ...playerMeasurements,
      ...measurements
    };
    return { props, measure };
  }
}

function filterEmptyProps<T>(obj?: { [key: string]: T | undefined; }): { [key: string]: T; } {
  if (!obj) {
    return {};
  }
  return Object.keys(obj).reduce((o, k) => {
    const val = obj[k];
    if (typeof val !== 'undefined') {
      o[k] = val;
    }
    return o;
  }, {} as { [key: string]: T; });
}

class PlayerError extends Error {
  constructor(message: string) {
    super(message);

    Object.setPrototypeOf(this, PlayerError.prototype);
  }

  get name() {
    return 'PlayerError';
  }

  toString() {
    return `${this.name}: ${this.message}`;
  }
}
