import { nrkHelp } from '@nrk/core-icons';
import overlay from '../overlay';
import uiEvents from '../../ui/events';
import applicationTracker from '../../../nrk/applicationTracker';
import { ExtendedLudo } from '../../../ludo/interfaces';
import { LudoUIType } from '../../ui/LudoUI';

export default (ui: LudoUIType) => {
  const helpButton = ui.element.querySelector<HTMLButtonElement>('button[name="help"]')!;
  const helpOverlayContent = ui.element.querySelector<HTMLElement>('.ludo-help')!;
  const helpOverlay: ReturnType<typeof overlay> = overlay(ui);

  helpButton.innerHTML = nrkHelp;
  helpOverlayContent.innerHTML = helpOverlayContent.innerHTML.replace('--version--', ui.get('version') as string);
  helpOverlayContent.removeAttribute('hidden');
  helpOverlay.content(helpOverlayContent);
  helpOverlay.on('closed', () => helpButton.focus({ preventScroll: true }));

  let linkTrackingInitialized = false;

  return (player: ExtendedLudo) => {
    helpButton.addEventListener('click', () => ui.emit(uiEvents.TOGGLEHELPOVERLAY));
    ui.on(uiEvents.TOGGLEHELPOVERLAY, () => {
      if (!linkTrackingInitialized) {
        linkTrackingInitialized = true;
        initializeLinkTracking(player, helpOverlayContent);
      }

      const isVisible = helpOverlay.toggle();

      if (isVisible) {
        player.pause();
      }
    });
  };
};

function initializeLinkTracking(player: ExtendedLudo, overlayEl: HTMLElement) {
  // Track clicks on the help text links. They need to have a
  // data-action-name attribute in order to be tracked.
  const tracker = applicationTracker(player.get('playerId'));
  const linkElements = overlayEl.querySelectorAll('a');
  tracker.trackLinks(player, linkElements, 'help');
}
