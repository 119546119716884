import bows from 'bows';
import DIMENSIONS from './dimensions';
import METRICS from './metrics';
import coreAnalytics from '@nrk/core-analytics';

type Fields = UniversalAnalytics.FieldsObject;
type HitType = UniversalAnalytics.HitType;

export interface Tracker {
  send(hitType: HitType, fields: Fields): void;
  set(fields: Fields): void;
}

const LUDO_GA_TRACKING_ID = 'UA-3413696-54';
const trackers: { [id: string]: Tracker } = {};
const log = bows('ludo:ga:track');
const isInIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

function createTracker(trackerName: string, initialFields?: Fields): Tracker {

  const trackerTime: number = Date.now();

  const standardFields: Fields = {
    appName: 'ludo',
    [DIMENSIONS.isIframe]: `${isInIframe()}`
  };

  const sessionFields: Fields = {
    ...initialFields
  };
  const tracker = coreAnalytics.create();

  tracker('initialize', {
    ga: LUDO_GA_TRACKING_ID,
    trackerName
  });

  tracker(() => {
    window.ga(() => { // Ensures GA is loaded
      const gaTracker = window.ga.getByName(String(tracker.uuid));
      standardFields[DIMENSIONS.clientId] = gaTracker.get('clientId');
    });
  });

  function send(hitType: HitType, fields?: Fields) {
    const mergedFields = {
      ...standardFields,
      ...sessionFields,
      ...fields,
      [METRICS.trackerTime]: Date.now() - trackerTime
    };
    log(trackerName, 'send', hitType, mergedFields);

    // Use native GA as this gives acces to all modules
    tracker(() => {
      window.ga(`${tracker.uuid}.send`, hitType, mergedFields);
    });
  }

  function set(fields: Fields) {
    Object.assign(sessionFields, fields);
  }

  return {
    send,
    set
  };
}

export const getTracker = function getTracker(playerId: string, fields?: Fields): Tracker {
  const appVersion = fields && fields.appVersion || '';
  const trackerName = `ludo_v${appVersion.replace(/\W+/g, '_')}`;

  if (typeof trackers[playerId] !== 'undefined') {
    return trackers[playerId];
  }

  const tracker = createTracker(trackerName, {
    [DIMENSIONS.playerId]: playerId,
    ...fields
  });

  trackers[playerId] = tracker;
  return tracker;
};
