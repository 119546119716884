/**
 * Loader for /playback/statistics/nielsen/{type}/{id}.
 */

import { PathLoader } from '../PathLoader';

type APIClient = import('../types').APIClient;
type NielsenStatistics = import('./response/NielsenStatistics').NielsenStatistics;

export class NielsenStatisticsLoader extends PathLoader<NielsenStatistics> {
  constructor(client: APIClient, path: string) {
    super(client, path.replace(/{.*}$/, '')); // Remove any placeholder.
  }
}
