type EPGProgram = import('./types').EPGProgram;

export interface GroupedByTime {
  previous: EPGProgram[];
  current: EPGProgram | null;
  next: EPGProgram[];
}

const ADDITIONAL_LEAD_TIME = 30000;

export class ProgramList {
  private programs: EPGProgram[] = [];

  set(programs: EPGProgram[]) {
    this.programs = programs;
  }

  groupByTime(time: number, withAdditionalLeadTime?: boolean): GroupedByTime {
    const leadTime = withAdditionalLeadTime ? ADDITIONAL_LEAD_TIME : 0;

    const data = {
      previous: [],
      current: null,
      next: []
    } as GroupedByTime;

    // tslint:disable prefer-for-of
    for (let i = 0; i < this.programs.length; ++i) {
      const program = this.programs[i];

      if (program.actualEndUTC < time) {
        data.previous.push(program);
      } else if (program.actualStartUTC - leadTime > time) {
        data.next.push(program);
      } else {
        // Adjustment for lead time. The next program is preferred over the
        // almost ended program.
        if (data.current) {
          data.previous.push(data.current);
        }
        data.current = program;
      }
    }

    return data;
  }

  getRemainingBufferTime(fromTicks: number): number | null {
    const lastProgram = this.getLastProgram();
    if (!lastProgram) {
      return null;
    }
    if (lastProgram.actualEndUTC < fromTicks) {
      return null;
    }
    return lastProgram.actualEndUTC - fromTicks;
  }

  private getLastProgram() {
    return this.programs.length ? this.programs[this.programs.length - 1] : null;
  }
}
