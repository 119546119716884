import bows from 'bows';
import coreAnalytics from '@nrk/core-analytics';
import { AnalyticsConfig, ExtendedLudo } from '../../ludo/interfaces';
import { MediaItem } from '../mediaItem/MediaItem';

// https://github.com/nrkno/analytics-docs/blob/master/media.md

export const DIMENSION = {
  id                : 'dimension1',
  title             : 'dimension2',
  year              : 'dimension3',
  month             : 'dimension4',
  day               : 'dimension5',
  referrerDomain    : 'dimension6',
  referrer          : 'dimension7',
  userIdHit         : 'dimension8',
  userIdSession     : 'dimension9',
  sourceId          : 'dimension10',

  category          : 'dimension23',
  autoplay          : 'dimension24',          // [Yes | No]
  mediaType         : 'dimension25',          // [video | audio]
  mediaCategory     : 'dimension26',          // [live | ondemand]
  mimeType          : 'dimension27',
  adapterType       : 'dimension28',          // [Flash | HTML5 | HLS | HLS mha. SDK]
  // service         : 'dimension29',          // [tvweb | radioweb | tvapp | radioapp | smarttv | atv | nrkno | embed]
  errorType         : 'dimension31',          // [METADATA_LOADING_ERROR | MEDIA_PREPARATION_ERROR | MEDIA_PLAYBACK_ERROR_DECODE]
  errorReason       : 'dimension30'          // [NETWORK_ERROR | MEDIA_ERROR | OTHER_ERROR]
  // isContinous     : 'dimension32'           // only one normalized time range is played
};

/* Not in use:
export const METRIC = {
  quantileTime      : 'metric22',
  totalTime         : 'metric23',
  starts            : 'metric21'
};
*/

export interface GoogleAnalyticsFields {
  [key: string]: any;
}

const generateTrackerName = (() => {
  let i = 0;
  return () => `ludo_${i++}`;
})();

let log: ReturnType<typeof bows>;

function getAutoplayField(player: ExtendedLudo): GoogleAnalyticsFields {
  return {
    [DIMENSION.autoplay]: player.get('autoplay') ? 'Yes' : 'No'
  };
}

/*
 * Collects all GA fields provided through the Ludo options.
 */
function getConfiguredFields(player: ExtendedLudo, analyticsConfig: AnalyticsConfig) {
  const { sourceId } = analyticsConfig;
  const ga = analyticsConfig.ga;
  const dimensions = ga && ga.dimensions || {};

  const fields = getAutoplayField(player);

  if (sourceId) {
    fields[DIMENSION.sourceId] = sourceId;
  }

  Object.keys(dimensions).forEach((key) => {
    const num = parseInt(key, 10);
    if (num > 0) {
      fields[`dimension${num}`] = dimensions[key];
    }
  });

  return fields;
}

/*
 * Collects media item related fields.
 */
export function getMediaItemFields(mediaItem?: MediaItem): UniversalAnalytics.FieldsObject {
  if (!mediaItem) {
    return {};
  }

  return {
    eventLabel: mediaItem.title,
    ...mediaItem.gaStats || {}
  };
}

function isValidDimensionOrMetricValue(value: any) {
  return typeof value !== 'undefined'
    && value !== null
    && ((typeof value === 'string' && value.length > 0) || typeof value === 'number');
}

function filterFields(fields: GoogleAnalyticsFields): GoogleAnalyticsFields {
  return Object.keys(fields).reduce((filteredFields: GoogleAnalyticsFields, name) => {
    const value = fields[name];
    if (isValidDimensionOrMetricValue(value)) {
      filteredFields[name] = value;
    }
    return filteredFields;
  }, {});
}

function getStandardFields(player: ExtendedLudo, analyticsConfig: AnalyticsConfig) {
  return {
    appName: 'ludo',
    appVersion: player.get('version'),
    ...getMediaItemFields(player.current()),
    ...getConfiguredFields(player, analyticsConfig)
  };
}

function getPlayerFields(player: ExtendedLudo) {
  const fields: GoogleAnalyticsFields = {};
  const source = player.currentSource();
  if (source) {
    fields[DIMENSION.mimeType] = source.type;
  }
  fields[DIMENSION.adapterType] = player.adapterName();

  return fields;
}

export default (player: ExtendedLudo, analyticsConfig: AnalyticsConfig, container: any = {}) => {
  const {
    debug = bows
  } = container;

  log = debug('ludo:ga:tracker');

  const trackerName = generateTrackerName();
  const tracker = coreAnalytics.create();
  // Expose tracker instance so that consumers can
  // listen for events from media-analytics
  // E.g. tracker.on('event.personalization', ({ga}) => {});
  // Used for eCommerce-tracking that rely on events emitted by media-analytics
  player.set('coreAnalyticsInstance', tracker);

  log(`Creating tracker '${trackerName}' with options`, analyticsConfig);

  tracker('initialize', {
    ga: analyticsConfig.ga.trackingId!, // index.ts checks trackingId.
    trackerName
  });

  if (analyticsConfig.userId) {
    tracker('context', {
      userId: analyticsConfig.userId
    });
  }

  function getFields() {
    return {
      ...getStandardFields(player, analyticsConfig),
      ...getPlayerFields(player)
    };
  }

  return {
    getFields,
    send(hitType: string, hitFields: GoogleAnalyticsFields) {
      const fields = filterFields({
        ...getFields(),
        ...hitFields,
        ...getConfiguredFields(player, analyticsConfig)
      });

      if (fields[DIMENSION.adapterType] === 'LudoCastPlayerAdapter') {
        log('Skip tracking of LudoCastPlayerAdapter', fields.eventAction);
        return;
      }

      // @ts-ignore
      tracker(hitType, { ga: fields });
    }
  };
};
