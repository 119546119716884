import { LudoError, LudoEvents } from '@nrk/ludo-core';
import { classToggle } from '../../dom';
import { contentNotSupported } from './platformDependentMessages';
import applicationTracker from '../../../nrk/applicationTracker';
import { ExtendedLudo } from '../../../ludo/interfaces';
import { LudoUIType } from '../../ui/LudoUI';

const REASONS = LudoError.REASONS;

const ACTIVE_CLASSNAME = 'ludo-notifications--active';

export default (ui: LudoUIType) => {
  require('./notifications.styl');

  const element = ui.element.querySelector<HTMLElement>('.ludo-notifications')!;
  let displayNotificationTime: number | null;

  return (player: ExtendedLudo) => {

    function displayNotification(message: string, {
      modal = false,
      feedback = true
    } = {}) {
      const notificationElement = document.createElement('div');
      const notificationMessageElement = document.createElement('span');

      notificationElement.classList.add('ludo-notifications__notification');
      classToggle(notificationElement, 'ludo-notifications__notification-modal', modal);

      notificationMessageElement.innerHTML = message;
      notificationElement.appendChild(notificationMessageElement);

      if (feedback) {
        const feedbackElement = document.createElement('span');
        feedbackElement.innerHTML = 'Kontakt <a data-action-name="publikumsservice" href="https://www.nrk.no/kontakt/">NRK Publikumsservice</a> dersom problemet vedvarer.';
        feedbackElement.classList.add('ludo-notifications__feedback');
        notificationElement.appendChild(feedbackElement);
      }

      element.appendChild(notificationElement);

      // Track clicks on the error message links. They need to have a
      // data-action-name attribute in order to be tracked.
      const tracker = applicationTracker(player.get('playerId'));
      const linkElementsNodeList = notificationElement.querySelectorAll('a');
      tracker.trackLinks(player, linkElementsNodeList, 'errorOverlay');

      displayNotificationTime = Date.now();
    }

    function hideNotifications() {
      while (element.firstChild) {
        element.removeChild(element.firstChild);
      }
      element.classList.remove(ACTIVE_CLASSNAME);
      displayNotificationTime = null;
    }

    function hideNotificationsWhenPlaying() {
      let lastTime: number;

      player.on(LudoEvents.TIMEUPDATE, (time) => {
        if (!displayNotificationTime || Date.now() < displayNotificationTime + 3000) {
          return;
        }
        if (lastTime && time > lastTime) {
          hideNotifications();
        }
        lastTime = time;
      });
    }

    const activeDisplayModalNotification = (message: string, options = {}) => {
      displayNotification(message, {
        modal: true,
        ...options
      });
      element.classList.add(ACTIVE_CLASSNAME);
    };

    player.on(LudoEvents.ERROR_READABLE, (errorMessage, reason) => {
      const current = player.current();

      let userMessage = errorMessage;
      let feedback = current ? current.isPlayable : false;

      if (reason === REASONS.MEDIA_SUPPORT_ERROR) {
        userMessage = contentNotSupported(player);
        feedback = false;
      }

      activeDisplayModalNotification(userMessage, {
        feedback
      });
    });

    player.on(LudoEvents.ITEM_CHANGED, () => {
      hideNotifications();
    });

    hideNotificationsWhenPlaying();
  };
};
