import {
  DEFAULT_MANIFEST_NAME,
  IndexPoint,
  LegalAge,
  MediaItem,
  Poster,
  Stream,
  Subtitle,
  Thumbnail
} from '../MediaItem';
import {
  IImagesItem,
  IPlaybackMetadata,
  SeekToPoint,
  NextUpPoint,
  INonPlayable,
  IAvailability as MetadataAvailability
} from '../../APIClient/psapi/response/IPlaybackMetadata';
import {
  IPlaybackManifest,
  IAvailability as ManifestAvailability
} from '../../APIClient/psapi/response/IPlaybackManifest';
import { parse, toSeconds } from 'iso8601-duration';
import { LudoBufferTypes, LudoMediaSource } from '@nrk/ludo-core';
import MessageTypes from './MessageTypes';
import arrayFind from 'array-find';

const HLS_MIME_TYPE = 'application/vnd.apple.mpegurl';
const MPEG_MIME_TYPE = 'audio/mpeg';

enum StreamingMode {
  Live = 'live',
  OnDemand = 'onDemand'
}

enum SourceMedium {
  Video = 'video',
  Audio = 'audio'
}

enum LiveType {
  Channel = 'channel'
}

function parseDate(dateString?: string): Date | undefined {
  if (!dateString) {
    return;
  }
  const parsed = new Date(dateString);
  if (!parsed.getTime()) {
    return;
  }
  return parsed;
}

export class PlaybackMediaItem implements MediaItem {
  private metadata: IPlaybackMetadata;
  private manifest?: IPlaybackManifest;
  readonly manifestName: string;
  startTime?: number;

  constructor(metadata: IPlaybackMetadata, manifestName: string = DEFAULT_MANIFEST_NAME, manifest?: IPlaybackManifest) {
    this.metadata = metadata;
    this.manifestName = manifestName;
    this.manifest = manifest;
  }

  get akamaiStats(): any {
    return this.manifest?.statistics.luna;
  }

  get availableFrom(): Date | undefined {
    const dateString = this.isLive ?
      this.availability.live?.transmissionInterval?.from
      : this.availability.onDemand?.from;
    return parseDate(dateString);
  }

  get availableTo(): Date | undefined {
    const dateString = this.isLive ?
      this.availability.live?.transmissionInterval?.to
      : this.availability.onDemand?.to;
    const date = parseDate(dateString);

    // Normalize "forever" to undefined.
    return date && date.getFullYear() > 9000 ? undefined : date;
  }

  get duration() {
    const d = this.metadata.duration;
    return d ? toSeconds(parse(d)) : 0;
  }

  get gaStats(): any {
    return this.manifest?.statistics.ga;
  }

  get id(): string {
    return this.metadata.id;
  }

  get indexPoints(): IndexPoint[] {
    return this.metadata.preplay.indexPoints;
  }

  get isAudio(): boolean {
    return this.manifest?.sourceMedium === SourceMedium.Audio;
  }

  get isChannel(): boolean {
    return this.availability.live?.type === LiveType.Channel;
  }

  get isOngoing(): boolean {
    return !!this.availability.live?.isOngoing;
  }

  get legalAge(): LegalAge | undefined {
    const rating = this.metadata.legalAge.body.rating;
    if (!rating) {
      return;
    }
    return {
      code: rating.code,
      badge: rating.displayAge,
      text: rating.displayValue
    };
  }

  get poster(): string | undefined {
    const posters = this.posters;
    if (posters.length > 2) {
      return posters[2].src;
    }
    if (posters.length === 0) {
      // Podcast episodes have the poster image in the embedded podcast data.
      return this.metadata._embedded?.podcast?.imageUrl;
    }
    return undefined;
  }

  get posters(): Poster[] {
    return this.metadata.preplay.poster.images
      .map((image: IImagesItem) => {
        return {
          src: image.url.replace(/^http:/, 'https:'),
          width: image.pixelWidth
        };
      });
  }

  get programRightsHasNotStarted(): boolean {
    return this.getNonPlayableMessageType() === 'ProgramRightsHasNotStarted';
  }

  get scoresStats(): any {
    return this.manifest?.statistics.scores;
  }

  get streams(): Stream[] {
    return (this.metadata._embedded?.manifests ?? []).map((m) => ({
      id: m.id || '',
      label: m.availabilityLabel || '',
      value: m._links!.self.name || '',
      href: m._links!.self.href
    }));
  }

  get subtitle(): string | undefined {
    return this.metadata.preplay.titles.subtitle || '';
  }

  get subtitles(): Subtitle[] {
    return (this.manifest?.playable?.subtitles ?? [])
      .map((sub) => ({
        src: sub.webVtt,
        srclang: sub.language,
        label: sub.label,
        default: typeof sub.defaultOn !== 'undefined' ? sub.defaultOn : true,
        type: sub.type
      }))
      .filter((sub) => sub.src);
  }

  get thumbnails(): Thumbnail[] {
    return [];
  }

  get sources(): LudoMediaSource[] {
    return (this.manifest?.playable?.assets ?? [])
      .map((asset) => ({
        src: asset.url,
        type: asset.mimeType
      }));
  }

  get title(): string | undefined {
    return this.metadata.preplay.titles.title;
  }

  get isPlayable(): boolean {
    return !this.isBlocked && (this.sources.length > 0 || this.playability === 'playable');
  }

  get isLive(): boolean {
    return this.metadata.streamingMode === StreamingMode.Live;
  }

  get isVideo(): boolean {
    return this.manifest?.sourceMedium === SourceMedium.Video;
  }

  get isBlocked(): boolean | undefined {
    const reason = this.nonPlayable?.reason;
    return reason === 'blocked' || reason === 'unavailable' || reason === 'notransmission';
  }

  get blockedMessage(): string | undefined {
    return this.nonPlayable?.endUserMessage || this.getNonPlayableMessageByType();
  }

  get bufferDuration(): number {
    const d = this.manifest?.playable?.liveBuffer?.bufferDuration;
    if (d) {
      return toSeconds(parse(d));
    }
    return this.isLive ? 0 : this.duration;
  }

  get bufferType(): LudoBufferTypes {
    if (!this.isLive) {
      return LudoBufferTypes.FIXED; // OnDemand
    }

    const bufferType = this.manifest?.playable?.liveBuffer?.bufferType;

    const mapping: { [key: string]: LudoBufferTypes } = {
      sliding: LudoBufferTypes.SLIDING,
      growing: LudoBufferTypes.GROWING,
      none: LudoBufferTypes.NONE
    };

    if (bufferType && bufferType in mapping) {
      return mapping[bufferType];
    }

    // To be removed when the PSAPI always provides bufferType.
    return this.detectBufferType();
  }

  private getNonPlayableMessageType(): string | undefined {
    return this.nonPlayable?.messageType;
  }

  private getNonPlayableMessageByType(): string | undefined {
    const messageType = this.getNonPlayableMessageType() as (keyof typeof MessageTypes) | undefined;
    if (!messageType) {
      return;
    }
    const messageProvider = MessageTypes[messageType];
    if (typeof messageProvider === 'function') {
      return messageProvider(this);
    } else if (typeof messageProvider === 'string') {
      return messageProvider;
    }
    return;
  }

  get requireManifestLoad() {
    if (this.isPlayable && !this.manifest) {
      return true;
    }
    return false;
  }

  get interactionPoints() {
    const interactionPoints = this.metadata.interaction;
    if (!interactionPoints) {
      return undefined;
    }

    const intro = arrayFind(interactionPoints, (p) => p.type === 'seekToPoint') as SeekToPoint | undefined;
    const nextUp = arrayFind(interactionPoints, (p) => p.type === 'nextUpPoint') as NextUpPoint | undefined;
    return {
      intro,
      nextUp
    };
  }

  get hasNextItem() {
    return !!this.metadata._links.next;
  }

  get mimeTypes() {
    if (this.sources.length) {
      return this.sources.map((source) => source.type);
    }
    const playableLink = this.metadata.playable?.resolve;
    const assumedMimetype = playableLink && /podcast/.test(playableLink) ? MPEG_MIME_TYPE : HLS_MIME_TYPE;
    return assumedMimetype ? [assumedMimetype] : [];
  }

  get epgLiveBufferPath() {
    return this.metadata._links.epgBuffer?.href;
  }

  // For availability and playability information, prefer the manifest when
  // available. The metadata and manifest are not always in sync.

  private get availability(): MetadataAvailability | ManifestAvailability {
    return (this.manifest ?? this.metadata).availability;
  }

  private get playability(): string {
    return (this.manifest ?? this.metadata).playability;
  }

  private get nonPlayable(): INonPlayable | undefined {
    return (this.manifest ?? this.metadata).nonPlayable || undefined;
  }

  /**
   * Old hacky buffer type detection. Should be removed.
   */
  private detectBufferType(): LudoBufferTypes {
    if (this.isChannel) {
      return LudoBufferTypes.SLIDING; // Channel
    }
    if (/-/.test(this.id)) {
      return LudoBufferTypes.GROWING; // FOSS2
    }
    return LudoBufferTypes.NONE;
  }
}
