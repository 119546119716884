import { ExtendedLudo } from '../../ludo/interfaces';
import createAudienceTracker, { getMediaItemFields } from './tracker';
import { NrkEvents } from '../index';
import { PlaybackSessionState } from '../../ludo/session/PlaybackSession';
import { MediaItem } from '../mediaItem/MediaItem';

export function trackPlayed(player: ExtendedLudo, tracker: ReturnType<typeof createAudienceTracker>) {
  player.on(NrkEvents.PLAYBACK_ENDED, (session: PlaybackSessionState, mediaItem: MediaItem) => {
    if (session.isLive) {
      return;
    }

    const part = Math.min(session.distinctPlayedDuration / session.duration, 1);
    const percentageWatched = Math.round(part * 100 || 0);
    tracker.send('event', {
      eventCategory: 'content-consumed',
      eventAction: 'percentConsumed',
      eventValue: percentageWatched,

      // The current mediaitem on player might have been changed
      // We need to override with data from the PLAYBACK_ENDED event
      ...getMediaItemFields(mediaItem)
    });
  });
}
