import { LudoEvents } from '@nrk/ludo-core';
import NrkEvents from '../NrkEvents';
import logging from 'bows';

type ProgramList = import('./ProgramList').ProgramList;
type ExtendedLudo = import('../../ludo/interfaces').ExtendedLudo;
type EPGProgram = import('./types').EPGProgram;

const IMMINENCE_THRESHOLD = 90 * 1000;
const TIMEOUT_EASING = 100;

const log = logging('ludo:epg:track');

/**
 * Based on an EPG and the player's current time, emit events related to
 * program changes.
 */
export class LiveEPGTracker {
  private player: ExtendedLudo;
  private programList: ProgramList;
  private destroyed: boolean = false;
  private checkTimeoutId?: number;
  private currentProgram?: EPGProgram | null;

  constructor(player: ExtendedLudo, programList: ProgramList) {
    this.player = player;
    this.programList = programList;
    this.bind();
  }

  destroy() {
    this.destroyed = true;
    this.clearCheckTimeout();
    this.unbind();
    this.endCurrentProgram();
  }

  private bind() {
    this.player.on(NrkEvents.EPG_UPDATED, this.check, this);
    this.player.on(LudoEvents.SEEKED, this.checkWithSeek, this);
  }

  private unbind() {
    this.player.off(NrkEvents.EPG_UPDATED, this.check, this);
    this.player.off(LudoEvents.SEEKED, this.checkWithSeek, this);
  }

  private clearCheckTimeout() {
    if (this.checkTimeoutId) {
      window.clearTimeout(this.checkTimeoutId);
      this.checkTimeoutId = 0;
    }
  }

  private check({ seek = false } = {}) {
    this.clearCheckTimeout();

    const now = this.player.currentLiveTime().getTime();
    const epg = this.programList.groupByTime(now, seek);
    const currentProgram = epg.current;
    const nextProgram = epg.next.length ? epg.next[0] : null;

    // When to check next time?
    const currentEnd = currentProgram ? currentProgram.actualEndUTC - now : Infinity;
    const nextStart = nextProgram ? nextProgram.actualStartUTC - now : Infinity;
    const nextImminent = nextStart > IMMINENCE_THRESHOLD ? nextStart - IMMINENCE_THRESHOLD : Infinity;
    const nextCheck = Math.min(currentEnd, nextStart, nextImminent);

    // Set up next checkpoint.
    if (isFinite(nextCheck) && !this.destroyed) {
      log(`Next checkpoint in ${nextCheck / 1000} seconds.`);
      this.checkTimeoutId = window.setTimeout(this.check.bind(this), nextCheck + TIMEOUT_EASING);
    }

    // Has current program changed?
    if (this.currentProgram !== currentProgram) {
      if (!currentProgram || !this.currentProgram || currentProgram.programId !== this.currentProgram.programId) {
        log('Live program changed:', currentProgram);
        this.player.emit(NrkEvents.LIVEPROGRAM_CHANGED, currentProgram);
      }
      this.currentProgram = currentProgram;
    }

    // Is the next program imminent?
    if (nextStart <= IMMINENCE_THRESHOLD) {
      log('Live program change imminent (at next checkpoint).');
      this.player.emit(NrkEvents.LIVEPROGRAM_CHANGE_IMMINENT, epg);
    }
  }

  private checkWithSeek() {
    this.check({ seek: true });
  }

  private endCurrentProgram() {
    if (this.currentProgram) {
      this.currentProgram = null;
      log('Live program changed:', null);
      this.player.emit(NrkEvents.LIVEPROGRAM_CHANGED, null);
    }
  }
}
