type FieldName = keyof UniversalAnalytics.FieldsObject;

export default {
  clientId         : 'dimension1',
  playerId         : 'dimension2',

  id               : 'dimension3',
  title            : 'dimension4',

  mediaType        : 'dimension5', // video | audio
  mediaCategory    : 'dimension6', // live | ondemand
  mediaUrl         : 'dimension7',
  mimeType         : 'dimension8',
  adapterName      : 'dimension9',

  errorReason      : 'dimension10',
  errorType        : 'dimension11',
  errorSeverity    : 'dimension12', // warning | fatal

  bitrate          : 'dimension13',
  qualityLevel     : 'dimension14',
  videoSize        : 'dimension15',

  CDN              : 'dimension16',
  mediaOrigin      : 'dimension17', // prf | live | foss | potion

  errorDetails     : 'dimension18',
  latestEvent      : 'dimension19',
  isIframe         : 'dimension20',

  reachedPrepare   : 'dimension21',
  reachedPrepared  : 'dimension22',
  reachedLoad      : 'dimension23',
  reachedLoaded    : 'dimension24',
  reachedPlay      : 'dimension25',
  reachedPlaying   : 'dimension26',

  configKey        : 'dimension27',
  style            : 'dimension28',
  autoplaySupport  : 'dimension29',
  errorURL         : 'dimension30'
} as { [key: string]: FieldName };
