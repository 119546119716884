/**
 * Listen to EPG events, and emit similar events, enriched with statistics
 * configurations/program metadata for Nielsen.
 */
import NrkEvents from '../NrkEvents';
import { LudoEvents } from '@nrk/ludo-core';
import logger from 'bows';
import { NielsenLiveProgramStatsLoader } from '../APIClient/psapi/NielsenLiveProgramStatsLoader';
import { getManifestLinks } from '../APIClient/psapi/PlaybackManifestLoader';
import { TimeWithTZ } from '../utilities/TimeWithTZ';
import { getTimeZoneOffset } from './timeZone';
import { DAY_CHANGED } from './DayTracker';
import { IPlaybackManifest } from '../APIClient/psapi/response/IPlaybackManifest';

type ExtendedLudo = import('../../ludo/interfaces').ExtendedLudo;
type EPGProgram = import('../LiveEpg/types').EPGProgram;
type GroupedByTime = import('../LiveEpg/ProgramList').GroupedByTime;
type NielsenLiveProgramStats = import('../APIClient/psapi/response/NielsenStatistics').NielsenLiveProgramStats;
type APIClient = import('../APIClient/types').APIClient;

export enum Events {
  LIVE_PROGRAM_CHANGED = 'nielsen-live-program-changed',
  LIVE_PROGRAM_UPDATED = 'nielsen-live-program-updated'
}

export interface ProgramEventData {
  readonly program: EPGProgram | null;
  readonly stats: NielsenLiveProgramStats;
}

const ACCEPTABLE_PROGRAM_CHANGE_DELAY = 900;
const PREFETCH_THRESHOLD = 5 * 60 * 1000; // Prefetch for programs 5 minutes into the future.
const LATEST_PREFETCH = 10 * 1000; // Prefetch at least 10 seconds before the program starts.
const MAX_PREFETCH_DELAY = 60 * 1000; // Don't wait for more than a minute before prefetching.

const log = logger('nielsen:live');

export class LiveEvents {
  private player: ExtendedLudo;
  private apiClient: APIClient;
  private isActive: boolean = false;
  private programLoader: NielsenLiveProgramStatsLoader | null;
  private currentChannel: string | null;
  private currentProgram?: EPGProgram | null;
  private programStats: { [key: string]: NielsenLiveProgramStats };

  constructor(player: ExtendedLudo, apiClient: APIClient) {
    this.player = player;
    this.apiClient = apiClient;
    this.programLoader = null;
    this.programStats = {};
    this.currentChannel = null;

    this.player.on(LudoEvents.LOADED, this.handleStreamChanged, this);
  }

  destroy() {
    this.player.off(LudoEvents.LOADED, this.handleStreamChanged, this);

    if (this.isActive) {
      this.deactivate();
    }
  }

  private activate() {
    log('Activating.');
    this.isActive = true;
    this.player.on(NrkEvents.EPG_UPDATED, this.handleEpgUpdated, this);
    this.player.on(NrkEvents.LIVEPROGRAM_CHANGED, this.handleProgramChanged, this);
    this.player.on(NrkEvents.LIVEPROGRAM_CHANGE_IMMINENT, this.handleProgramChangeImminent, this);
    this.player.on(DAY_CHANGED, this.handleDayChanged, this);
  }

  private deactivate() {
    log('Deactivating.');
    this.isActive = false;
    this.player.off(NrkEvents.EPG_UPDATED, this.handleEpgUpdated, this);
    this.player.off(NrkEvents.LIVEPROGRAM_CHANGED, this.handleProgramChanged, this);
    this.player.off(NrkEvents.LIVEPROGRAM_CHANGE_IMMINENT, this.handleProgramChangeImminent, this);
    this.player.off(DAY_CHANGED, this.handleDayChanged, this);
  }

  private handleStreamChanged() {
    const mediaItem = this.player.current();
    const channel = mediaItem && mediaItem.isChannel ? mediaItem.id : null;
    log(`Stream check: ${mediaItem && mediaItem.id} is ${channel ? '' : 'not '}a channel.`);

    if (this.currentChannel !== channel) {
      this.currentChannel = channel;
      this.currentProgram = null;
      this.programStats = {};
    }

    if (channel === null) {
      this.programLoader = null;

      if (this.isActive) {
        this.deactivate();
      }
    } else {
      // @ts-ignore
      const manifest: IPlaybackManifest = mediaItem.manifest; // Type violation!
      const loaders = getManifestLinks(this.apiClient, manifest);

      if (loaders.nielsenLiveProgramStats) {
        this.programLoader = loaders.nielsenLiveProgramStats;

        if (!this.isActive) {
          this.activate();
        }
      }
    }
  }

  /**
   * Check if the current program (not a gap) has stats configuration or not,
   * and potentially attempt another fetch.
   */
  private async handleEpgUpdated() {
    const program = this.currentProgram;

    if (program && !this.programStats[program.plannedStart]) {
      let stats;

      try {
        stats = await this.fetchStats(program);
      } catch (error) {
        log.warn(error);
        return;
      }

      this.emitUpdatedStats(program, stats);
    }
  }

  /**
   * When the program changes, emit a similar event, but with statistics
   * configuration. If the statistics configuration isn't immediately
   * available, try to fetch it fast, falling back to 'blank'.
   */
  private async handleProgramChanged(program: EPGProgram | null) {
    this.currentProgram = program;

    let stats: NielsenLiveProgramStats | null | undefined;

    if (program) {
      stats = this.programStats[program.plannedStart];
      if (!stats) {
        log(`Panic fetch of stats config for ${program.programId}/${program.plannedStart}.`);
        stats = await this.fetchStatsFast(program);
      }
    }

    if (!stats) {
      // Blank stats must be generated because there's no API that gives us
      // blank stats for a program gap before midnight if the seek buffer
      // crosses midnight.
      log('Generating blank stats');
      const position = this.player.currentLiveTime().getTime();
      const timeZoneOffset = getTimeZoneOffset(position);
      stats = this.generateBlankStats(timeZoneOffset);
    }

    this.player.emit(Events.LIVE_PROGRAM_CHANGED, { program, stats } as ProgramEventData);
  }

  /**
   * Preload statistics configurations for coming programs.
   *
   * It's possible that scheduled preloads become outdated if the user seeks
   * after scheduling. It shouldn't be a big problem if an extra request is
   * sent to the same URL as a result of seeking (what's the browser cache time?).
   */
  private handleProgramChangeImminent(programs: GroupedByTime) {
    let i = 0;
    const position = this.player.currentLiveTime().getTime();

    while (programs.next[i].actualStartUTC - position < PREFETCH_THRESHOLD) {
      const program = programs.next[i];

      if (!this.programStats[program.plannedStart]) {
        const maxDelay = Math.max(0, Math.min(program.actualStartUTC - position - LATEST_PREFETCH, MAX_PREFETCH_DELAY));
        const delay = Math.floor(Math.random() * maxDelay);

        log(`Prefetching stats config for ${program.programId}/${program.plannedStart} in ${delay / 1000} seconds.`);

        window.setTimeout(() => this.fetchStats(program).catch((error) => log.warn), delay);
      }
      ++i;
    }
  }

  /**
   * Gap stats change at midnight (Norwegian midnight).
   */
  private handleDayChanged({ tzOffset }: { tzOffset: number }) {
    if (this.currentProgram === null) {
      log('Re-generating blank stats');
      const stats = this.generateBlankStats(tzOffset);
      this.player.emit(Events.LIVE_PROGRAM_CHANGED, { program: null, stats } as ProgramEventData);
    }
  }

  /**
   * Fetch and store the stats for a live program. If the stream has changed
   * while fetching, nothing is stored and null is returned. Errors are thrown
   * to the caller.
   */
  private async fetchStats(program: EPGProgram): Promise<NielsenLiveProgramStats | null> {
    const channel = this.currentChannel!;
    const result = await this.programLoader!.load(program.plannedStart);

    if (this.currentChannel === channel) {
      log(`Got stats config for ${program.programId}/${program.plannedStart}`);
      this.programStats[program.plannedStart] = result.data;
      return result.data;
    }

    return null;
  }

  /**
   * Fetch the live stats for a program. It always resolves within
   * ACCEPTABLE_PROGRAM_CHANGE_DELAY, either with the fetched stats or null, if
   * the stats are not yet in or irrelevant. If the stats come later, then an
   * update event is emitted.
   */
  private async fetchStatsFast(program: EPGProgram): Promise<NielsenLiveProgramStats | null> {
    return new Promise<NielsenLiveProgramStats | null>(async (resolve) => {
      let alreadyResolved = false;
      let stats;

      // Resolve timeout.
      const timeoutId = window.setTimeout(() => {
        alreadyResolved = true;
        resolve(null);
      }, ACCEPTABLE_PROGRAM_CHANGE_DELAY);

      // The fetch.
      try {
        stats = await this.fetchStats(program);
      } catch (error) {
        log.warn(error);

        // Check the resolve state.
        if (!alreadyResolved) {
          window.clearTimeout(timeoutId);
          resolve(null);
        }
        return;
      }

      // Check the resolve state.
      if (alreadyResolved) {
        this.emitUpdatedStats(program, stats);
      } else {
        window.clearTimeout(timeoutId);
        resolve(stats);
      }
    });
  }

  private generateBlankStats(timeZoneOffset: number): NielsenLiveProgramStats {
    const playerPosition = this.player.currentLiveTime();
    const time = playerPosition.getTime();
    const day = 24 * 60 * 60 * 1000;
    const dayStart = new TimeWithTZ(time - time % day, timeZoneOffset);
    const dayEnd = new TimeWithTZ(dayStart.utc + day, timeZoneOffset);

    return {
      assetid: `unknown-${this.currentChannel}-${dayStart.toCompactLocalDate()}`,
      program: 'none',
      title: 'none',
      length: '86400',
      airdate: dayStart.toModernPSAPIFormat(),
      scheduledEndDate: dayEnd.toModernPSAPIFormat(),
      isfullepisode: 'n'
    };
  }

  /**
   * Emit updated stats, if there are stats to emit, and the program hasn't
   * already changed.
   */
  private emitUpdatedStats(program: EPGProgram, stats: NielsenLiveProgramStats | null) {
    if (stats && this.currentProgram && this.currentProgram.programId === program.programId) {
      this.player.emit(Events.LIVE_PROGRAM_UPDATED, { program, stats } as ProgramEventData);
    }
  }
}
