import { ExtendedLudo } from '../../ludo/interfaces';

export interface PlayerProperties {
  adapterName?: string;
  id?: string;
  mediaType?: string;
  mediaCategory?: string;
  title?: string;
  CDN?: string;
  mediaOrigin?: string;
  mediaUrl?: string;
  mimeType?: string;
  qualitySize?: string;
}

export interface PlayerMeasurements {
  currentTime?: number;
  duration?: number;
}

export function getPlayerValues(player: ExtendedLudo): [PlayerProperties, PlayerMeasurements] {
  const properties: PlayerProperties = {};
  const measurements: PlayerMeasurements = {};

  if (!player) {
    return [properties, measurements];
  }

  const adapterName = player.adapterName();
  if (adapterName && adapterName.length) {
    properties.adapterName = adapterName;
  }

  measurements.currentTime = Math.floor(player.currentTime() * 1000);
  measurements.duration = Math.floor(player.duration() * 1000);

  const mediaItem = player.current();

  if (mediaItem) {
    const akamaiData = mediaItem.akamaiStats && mediaItem.akamaiStats.data;
    const CDN = akamaiData && akamaiData.cdnName;
    const dimension1 = mediaItem.gaStats && mediaItem.gaStats.dimension1;
    const mediaOrigin = /:/.test(dimension1) && dimension1.split(':')[0];

    const { id, isVideo, isLive, title } = mediaItem;

    properties.id = id;
    properties.mediaType = isVideo ? 'video' : 'audio';
    properties.mediaCategory = isLive ? 'live' : 'ondemand';
    properties.title = title;
    properties.CDN = CDN;
    properties.mediaOrigin = mediaOrigin;

    if (mediaItem.sources && mediaItem.sources.length) {
      const source = mediaItem.sources[0];
      properties.mediaUrl = source.src;
      properties.mimeType = source.type;
    }
  }

  const currentQuality = player.getPlaybackQuality();
  if (currentQuality && currentQuality.width) {
    properties.qualitySize = `${currentQuality.width}x${currentQuality.height}`;
  }

  return [properties, measurements];
}
