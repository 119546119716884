import { LudoEvents } from '@nrk/ludo-core';
import DIMENSIONS from './dimensions';
import { ExtendedLudo } from '../../ludo/interfaces';
import { SendEvent } from './index';

type Fields = UniversalAnalytics.FieldsObject;

const trackedEvents: { [message: string]: boolean } = {}; // { <message>: <fatal> }

export default (player: ExtendedLudo, sendEvent: SendEvent) => {
  player.on(LudoEvents.ERROR, (error, errorData = {}) => {
    const current = player.current();

    if (!errorData.fatal) {
      return;
    }

    if (current && !current.isPlayable) {
      return;
    }

    const errorMessageParts: string[] = [];
    if (error) {
      const errorName = error.name || error.constructor.name;
      if (errorName) {
        errorMessageParts.push(`${errorName}: `);
      }
      if (error.message) {
        errorMessageParts.push(error.message);
      }
      if (error.code) {
        errorMessageParts.push(`[code:${error.code}]`);
      }
      if (!errorMessageParts.length) {
        errorMessageParts.push(error.toString());
      }
    }

    const errorMessage = errorMessageParts.join('');
    const errorSeverity = errorData.fatal ? 'fatal' : 'warning';

    const eventCategory = 'error';
    const eventAction = `error-${errorSeverity}`;
    const eventLabel = errorMessage;

    // Filter error duplicates
    if (typeof trackedEvents[errorMessage] !== 'undefined') {
      return;
    }

    trackedEvents[errorMessage] = errorData.fatal;

    const fields: Fields = {
      [DIMENSIONS.errorType]: errorData.type,
      [DIMENSIONS.errorReason]: errorData.reason,
      [DIMENSIONS.errorSeverity]: errorSeverity
    };

    if (error) {
      if (error.stack) {
        fields[DIMENSIONS.errorDetails] = error.stack;
      }
      if (error.url) {
        fields[DIMENSIONS.errorURL] = error.url;
      }
    }

    sendEvent(eventCategory, eventAction, eventLabel, 0, fields);
  });
};
