/*
 * This module provides access to the Nielsen SDK. First, the SDK needs to be
 * enabled with enable(). Then, newInstance() can be used for creating tracking
 * instances tied to an app ID.
 *
 * SDK documentation:
 * https://engineeringportal.nielsen.com/docs/DCR_Norway_Audio_Browser_SDK
 */
import cuid from 'cuid';

export interface NielsenSDKInstance {
  loadMetadata: (metadata: object) => void;
  stop: (playheadPosition: number) => void;
  end: (playheadPosition: number) => void;
  setPlayheadPosition: (playheadPosition: number) => void;
}

type NielsenSDKMethod = keyof NielsenSDKInstance;

// The namespace is hard-coded inside the Nielsen SDK, so it can't be changed.
const NIELSEN_NAMESPACE = 'NOLBUNDLE';

/*
 * Enable the Nielsen SDK. This is a copy of the Nielsen "Static Queue
 * Snippet", only modified to get the Nielsen namespace from the constant
 * above (and reformatted).
 *
 * Note: The SDK isn't actually loaded here. The SDK is loaded when the first
 * SDK instance is created.
 */
export function enable() {
  // tslint:disable-next-line
  !function(t, n): any {
    // @ts-ignore
    t[n] = t[n] || {
      // @ts-ignore
      nlsQ(e, o, c, r, s, i) {
        return s = t.document,
          r = s.createElement('script'),
          r.async = 1,
          r.src = ('http:' === t.location.protocol ? 'http:' : 'https:') + '//cdn-gl.imrworldwide.com/conf/' + e + '.js#name=' + o + '&ns=' + n,
          i = s.getElementsByTagName('script')[0],
          i.parentNode.insertBefore(r, i),
          // @ts-ignore
          t[n][o] = t[n][o] || {
            g: c || {},
            // @ts-ignore
            ggPM(e, c, r, s, i) { (t[n][o].q = t[n][o].q || []).push([e, c, r, s, i]); }
          // @ts-ignore
          }, t[n][o];
      }
    };
  }(window, NIELSEN_NAMESPACE);
}

/*
 * Create a new SDK instance and a slightly nicer API for it.
 */
export function newInstance(appId: string): NielsenSDKInstance {
  const API_METHODS: NielsenSDKMethod[] = ['loadMetadata', 'stop', 'end', 'setPlayheadPosition'];
  const name = cuid();
  const instance = (<any>window)[NIELSEN_NAMESPACE].nlsQ(appId, name);

  return API_METHODS.reduce((api, method) => {
    api[method] = (value: any) => instance.ggPM(method, value);
    return api;
  }, {} as NielsenSDKInstance);
}
