/*
 * Functionality for tracking streaming sessions with Nielsen. One instance can
 * only track one session at a time. The methods need to be called in
 * appropriate order, according to the Nielsen documentation.
 *
 * SDK documentation.
 * https://engineeringportal.nielsen.com/docs/DCR_Norway_Audio_Browser_SDK
 */
import logger from 'bows';
import { NielsenSDKInstance } from './sdk';
import { getManifestLinks } from '../APIClient/psapi/PlaybackManifestLoader';
import { PlaybackMediaItem } from '../mediaItem/playback/PlaybackMediaItem';

type APIClient = import('../APIClient/types').APIClient;
type ProgramEventData = import('./LiveEvents').ProgramEventData;
type ExtendedLudo = import('../../ludo/interfaces').ExtendedLudo;
type NielsenMetadata = import('../APIClient/psapi/response/NielsenStatistics').NielsenLiveProgramStats;
type NielsenStatisticsLoader = import('../APIClient/psapi/NielsenStatisticsLoader').NielsenStatisticsLoader;
type IPlaybackManifest = import('../APIClient/psapi/response/IPlaybackManifest').IPlaybackManifest;

interface SDK {
  newInstance: (appId: string) => NielsenSDKInstance;
}

const log = logger('nielsen:track');

export class NielsenTracker {
  private player: ExtendedLudo;
  private apiClient: APIClient;
  private newSDKInstance: () => NielsenSDKInstance;
  private isLiveStream: boolean;
  private metadata: NielsenMetadata | null;
  private sdkInstance: NielsenSDKInstance | null;
  private lastPosition: number | null;

  constructor(player: ExtendedLudo, apiClient: APIClient, sdk: SDK, appId: string) {
    this.player = player;
    this.apiClient = apiClient;
    this.newSDKInstance = sdk.newInstance.bind(sdk, appId);

    this.isLiveStream = false;
    this.metadata = null;
    this.sdkInstance = null;
    this.lastPosition = null;
  }

  /*
   * Prepare the metadata of a stream. If there's no metadata available, then
   * it resolves to false, and no tracking should be started.
   */
  prepareMetadata(): Promise<boolean> {
    if (this.player.adapterName() === 'LudoCastPlayerAdapter') {
      log('Not tracking remote playback adapters.');
      return Promise.resolve(false);
    }

    const mediaItem = this.player.current()!;
    if (!mediaItem.isAudio) {
      log('Not tracking video streams.');
      return Promise.resolve(false);
    }

    if (!(mediaItem instanceof PlaybackMediaItem)) {
      log('Unsupported media item type');
      return Promise.resolve(false);
    }

    const loader = this.createMetadataConfigLoader(mediaItem);
    if (!loader) {
      log('Unable to create a Nielsen API loader.');
      return Promise.resolve(false);
    }

    const mediaItemId = mediaItem.id;

    return this.loadMetadataConfig(loader)
      .then((metadata) => {
        const mediaItem = this.player.current();
        if (mediaItem && mediaItem.id === mediaItemId) {
          this.isLiveStream = mediaItem.isLive;
          this.metadata = this.processMetadata(metadata);
          this.lastPosition = null;
          log('Prepared with metadata:', metadata);
          return true;
        }
        log('Stream changed. Discarding metadata.');
        return false;
      })
      .catch((error) => {
        log('Failed to load metadata:', error);
        return false;
      });
  }

  /*
   * Start a tracking session by creating Nielsen SDK instance.
   */
  beginSession() {
    this.sdkInstance = this.newSDKInstance();
  }

  /*
   * Clear the tracking session.
   */
  endSession() {
    this.metadata = null;
    this.sdkInstance = null;
    this.lastPosition = null;
  }

  /*
   * Update the session metadata. Sending the updated metadata to Nielsen
   * requires calls to stop() and loadMetadata();
   */
  epgChanged(data: ProgramEventData) {
    if (this.metadata) {
      Object.assign(this.metadata, this.processMetadata(data.stats));
    }
  }

  /*
   * Indicate to Nielsen that a stream with the provided metadata is currently
   * playing.
   */
  loadMetadata() {
    if (this.sdkInstance && this.metadata) {
      this.sdkInstance.loadMetadata(this.metadata);
    }
  }

  /*
   * Feed the given current time to Nielsen (based on the TIMEUPDATE event).
   */
  setPlayheadPosition(time: number) {
    const position = this.toNielsenTime(time);
    if (position !== this.lastPosition && position > 0) {
      this.lastPosition = position;
      if (this.sdkInstance) {
        this.sdkInstance.setPlayheadPosition(position);
      }
    }
  }

  /*
   * Indicate to Nielsen that the stream has stopped (for any reason).
   */
  stop() {
    if (this.sdkInstance) {
      this.sdkInstance.stop(this.nielsenTime());
    }
  }

  /*
   * Indicate to Nielsen that the stream has ended (and browser closing).
   */
  end() {
    if (this.sdkInstance) {
      this.sdkInstance.end(this.nielsenTime());
    }
  }

  /*
   * channelName is mobile app specific. Should not be forwarded by web.
   */
  private processMetadata(metadata: NielsenMetadata): NielsenMetadata {
    const data = { ...metadata };
    delete data.channelName;
    return data;
  }

  /*
   * Nielsen time is the number of whole seconds into the stream.
   * From 0 for on-demand streams. From epoch for live streams.
   *
   * What about live-to-vod?
   */
  private nielsenTime(): number {
    return Math.floor(this.isLiveStream ?
      this.player.currentLiveTime().getTime() / 1000
      : this.player.currentTime());
  }

  /*
   * Converts a given player time to Nielsen time.
   */
  private toNielsenTime(time: number): number {
    return Math.floor(this.isLiveStream ?
      this.player.convertTimeToLiveTime(time).getTime() / 1000
      : time);
  }

  /*
   * Create a loader for loading Nielsen statistics configurations, if available.
   */
  private createMetadataConfigLoader(mediaItem: PlaybackMediaItem): NielsenStatisticsLoader | null {
    // @ts-ignore
    const manifest: IPlaybackManifest | undefined = mediaItem.manifest; // Type violation!
    if (!manifest) {
      return null;
    }
    const links = getManifestLinks(this.apiClient, manifest);
    return links.nielsenStatistics || null;
  }

  /*
   * Attempt to load Nielsen statistics configuration for a media item.
   */
  private loadMetadataConfig(loader: NielsenStatisticsLoader): Promise<NielsenMetadata> {
    return loader.load().then((result) => result.data);
  }
}
