/*
 * This module maps the current website URL to a nice site name. Known test
 * environments are detected and indicated in the resulting name.
 *
 * Usage:
 *
 *     const site = new Site(referrer);
 *
 *     console.log(site.isProduction);
 *     console.log(site.fullName);
 */
import arrayFind from 'array-find';
import { siteConfigs, SiteConfigData, SiteOptions } from './siteConfig';
import URL from 'url-parse';

const ENVIRONMENT_EMBED_DIRECTLY = 'embed-test';

interface URLParts {
  hostname: string;
  pathname: string;
}

interface ContainerOverrides {
  localLocation: URLParts;
  hasParent: boolean;
}

interface SiteData extends SiteConfigData {
  unobviousEnvironment?: boolean;
  location?: URLParts;
}

export class Site {
  private hasParent: boolean;
  private localSite: SiteData;
  private parentSite: SiteData;

  constructor(referrer?: string | null, container?: ContainerOverrides) {
    const {
      localLocation = window.location,
      hasParent = window.top !== window.self
    } = container || {};

    this.hasParent = hasParent;
    this.localSite = this.detectSite(localLocation) || {};
    this.parentSite = {};

    if (hasParent && referrer) {
      const parsedUrl = this.parseUrl(referrer);
      if (parsedUrl) {
        this.parentSite = this.detectSite(parsedUrl);
      }
    }
  }

  get isEmbedded(): boolean {
    return !!(this.hasParent && this.localSite.isVideoEmbed);
  }

  get environment(): string | null {
    if (this.parentSite.environment) {
      return this.parentSite.environment;
    }

    if (this.localSite.environment) {
      return this.localSite.environment;
    }

    // If the video embed page is being used directly, then assume it's for
    // testing.
    if (!this.hasParent && this.localSite.isVideoEmbed) {
      return ENVIRONMENT_EMBED_DIRECTLY;
    }

    return null;
  }

  get isProduction(): boolean {
    return !this.environment;
  }

  get name(): string {
    const name = this.parentSite.name || this.localSite.name;

    // Non-production sites are chosen to be reported by hostname instead of
    // any nice name. Unnamed sites are also falling back to hostname.
    if (!name || !this.isProduction) {
      if (this.parentSite.location && this.parentSite.location.hostname) {
        return this.parentSite.location.hostname;
      }

      // Completely unknown parent, and the embed page doesn't have an
      // interesting hostname. Use fullName to reveal the embed environment.
      if (this.isEmbedded) {
        return 'N/A';
      }

      if (this.localSite.location && this.localSite.location.hostname) {
        return this.localSite.location.hostname;
      }

      return 'N/A';
    }

    return name;
  }

  get fullName(): string {
    const tags: string[] = [];

    // Only include the environment in the full name if it's non-obvious or if
    // the video embed page is being used directly. Otherwise, the environment
    // is unnecessary and often becomes noise.
    if (this.environment
      && (this.parentSite.unobviousEnvironment
        || this.localSite.unobviousEnvironment
        || this.environment === ENVIRONMENT_EMBED_DIRECTLY)) {
      tags.push(this.environment);
    }

    if (this.isEmbedded) {
      tags.push('embed');
    }

    if (tags.length) {
      return `${this.name} (${tags.join(', ')})`;
    }

    return this.name;
  }

  get options(): SiteOptions {
    return this.parentSite.options || this.localSite.options || {};
  }

  // Parsing/detection

  private parseUrl(url: string): URLParts | null {
    return new URL(url);
  }

  private detectSite(location: URLParts): SiteData {
    const site: SiteData = { location };
    const { hostname, pathname } = location;
    const data = this.matchSite(hostname, pathname);

    if (data) {
      Object.assign(site, data);

      // Configured environments are assumed to be unobvious, as they wouldn't
      // be configured if they could be detected from the hostname.
      if (site.environment) {
        site.unobviousEnvironment = true;
      } else {
        const environment = this.matchEnvironment(hostname);
        if (environment) {
          site.environment = environment;
        }
      }
    }

    return site;
  }

  private matchSite(hostname: string, pathname: string): SiteConfigData | null {
    const siteConfig = arrayFind(siteConfigs, (patterns) => {
      return new RegExp(patterns.hostname).test(hostname)
        && (patterns.pathname ? new RegExp(patterns.pathname).test(pathname) : true);
    });
    return siteConfig ? siteConfig.data : null;
  }

  private matchEnvironment(hostname: string): string | null {
    const match = hostname.match(/\b(dev|preprod|stage|test)\b/);
    return match ? match[1] : null;
  }
}
