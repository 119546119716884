import { ExtendedLudo, AnalyticsConfig } from '../../ludo/interfaces';
import { SendEvent } from './index';
import { Site } from '../index';

export function trackMissingGaTrackingId(player: ExtendedLudo, sendEvent: SendEvent) {
  const analyticsConfig: AnalyticsConfig = player.get('analytics');
  const { trackingId, enabled } = analyticsConfig && analyticsConfig.ga || {} as typeof analyticsConfig.ga;

  const site = new Site(document.referrer);

  if (enabled && !trackingId && site.isProduction) {
    sendEvent('error', 'error-config', 'Missing ga.trackingId');
  }
}
