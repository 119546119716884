import { parse as parseDuration, toSeconds } from 'iso8601-duration';
import { EPGLiveBuffer } from '../APIClient/psapi/response/EPGLiveBuffer';
import { EPGProgram } from './types';
import { TimeWithTZ } from '../utilities/TimeWithTZ';

export function parse(data: EPGLiveBuffer = []): EPGProgram[] {
  return data.map((program) => {

    const plannedStartWithTZ = TimeWithTZ.parse(program.plannedStart)!;
    const actualStartWithTZ = TimeWithTZ.parse(program.actualStart);

    // New values for these properties. Note that duration changes type from
    // string to number.
    const duration = toSeconds(parseDuration(program.duration));
    const plannedStart = plannedStartWithTZ.toModernPSAPIFormat();
    const actualStart = (actualStartWithTZ || plannedStartWithTZ).toModernPSAPIFormat();

    // New properties.
    const actualStartUTC = (actualStartWithTZ || plannedStartWithTZ).utc;
    const actualEndUTC = actualStartUTC + duration * 1000;

    return {
      ...program,
      duration,
      plannedStart,
      actualStart,
      actualStartUTC,
      actualEndUTC
    };
  });
}
