/*
 * Nielsen tracking of audio streams. It's based on a state machine that reacts
 * on the player and browser events, in order to produce timely methods calls
 * to the Nielsen SDK.
 *
 * To debug Nielsen events, use the Nielsen browser extension or the GET
 * parameter: nol_sdkDebug=DEBUG.
 */
import logger from 'bows';
import * as sdk from './sdk';
import { NielsenTracker } from './NielsenTracker';
import { nielsenStateMachine } from './machine';
import { LiveEvents } from './LiveEvents';
import { DayTracker } from './DayTracker';

type ExtendedLudo = import('../../ludo/interfaces').ExtendedLudo;
type APIClient = import('../APIClient/types').APIClient;

enum NielsenAppID {
  Production = 'P9540841F-01EA-4B24-9AC1-C079C8947927',
  Test = 'T9540841F-01EA-4B24-9AC1-C079C8947927'
}

const log = logger('nielsen:core');

export function nielsenTracker(apiClient: APIClient, { production = false } = {}) {
  sdk.enable();

  return (player: ExtendedLudo) => {
    const appId = getAppId(production);
    if (!appId) {
      return;
    }

    const tracker = new NielsenTracker(player, apiClient, sdk, appId);
    const machine = nielsenStateMachine(player, tracker);

    machine.on('transition', (data) => {
      log(`Transitioned from '${data.from}' to '${data.to}'.`);
    });

    machine.on('noHandler', (data) => {
      log(`No such event '${data.name}' in state '${data.state}'.`);
    });

    machine.start();

    // tslint:disable-next-line
    new DayTracker(player);
    // tslint:disable-next-line
    new LiveEvents(player, apiClient);
  };
}

/**
 * Return the correct app ID for the session, or null if no tracking should
 * happen.
 */
function getAppId(production: boolean): NielsenAppID | null {
  return production ? NielsenAppID.Production : NielsenAppID.Test;
}
