import { nrkMediaTheater, nrkMediaTheaterActive } from '@nrk/core-icons';
import { LudoUIType } from '../../ui/LudoUI';
import { PublicEvents } from '../../../publicEventsApi';
import { ExtendedLudo } from '../../../ludo/interfaces';
import { LudoEvents } from '@nrk/ludo-core';
import applicationTracker from '../../../nrk/applicationTracker';
import CustomLudoEvents from '../../../cast/CustomLudoEvents';

export default (
  ui: LudoUIType,
  {
    initialState = false,
    applicationTracker: appTracker = applicationTracker
  } = {}
) => {
  const button = ui.element.querySelector<HTMLButtonElement>('button[name="theatre"]')!;
  button.innerHTML = nrkMediaTheater;
  button.setAttribute('aria-label', 'Fullbreddemodus, Av');
  button.removeAttribute('hidden');

  let state = initialState;
  const setState = (newState: boolean) => {
    state = newState;
    button.innerHTML = state ? nrkMediaTheaterActive : nrkMediaTheater;
  };
  setState(initialState);

  return (player: ExtendedLudo) => {
    player.on(CustomLudoEvents.CHROMECASTON, () => (button.disabled = true));
    player.on(CustomLudoEvents.CHROMECASTOFF, () => (button.disabled = false));
    player.on(LudoEvents.OPTIONCHANGED, (key, value) => {
      if (key === 'theaterModeVisible') {
        button[value ? 'removeAttribute' : 'setAttribute']('hidden', '');
      }
    });

    const tracker = appTracker(player.get('playerId'));
    button.addEventListener('click', (event: any) => {
      event.stopPropagation();
      const newState = !state;
      tracker.sendEvent(
        'click',
        'theater-mode-toggled',
        newState ? 'theater-mode-toggled-on' : 'theater-mode-toggled-off'
      );
      button.setAttribute('aria-label', newState ? 'Fullbreddemodus, På' : 'Fullbreddemodus, Av');
      player.emit(PublicEvents.THEATER_MODE_TOGGLED, newState);
      button.setAttribute('aria-pressed', String(newState));
    });

    player.on(PublicEvents.THEATER_MODE_TOGGLED, (activated: boolean) => setState(activated));
    player.on(LudoEvents.FULLSCREENON, () => button.setAttribute('hidden', ''));
    player.on(LudoEvents.FULLSCREENOFF, () => button.removeAttribute('hidden'));
  };
};
