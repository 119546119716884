import { getTracker } from './tracker';
import DIMENSIONS from './dimensions';
import METRICS from './metrics';
import trackTiming from './trackTiming';
import trackErrors from './trackErrors';
import trackLatestEvent from './trackLatestEvent';
import { ExtendedLudo } from '../../ludo/interfaces';
import { trackMissingGaTrackingId } from './trackMissingGaTrackingId';
import { dot } from '../utilities/dot';

type Fields = UniversalAnalytics.FieldsObject;

export type SendEvent = (category: string, action: string, label: string, value?: number, fields?: Fields) => void;

let pageviewSent = false;

export default (playerId: string, fields?: Fields) => {

  let currentPlayer: ExtendedLudo;

  const tracker = getTracker(playerId, fields);

  if (!pageviewSent) {
    tracker.send('pageview', {
      title: document.title,
      location: window.location.href
    });

    pageviewSent = true;
  }

  function getPlayerFields() {
    const fields: Fields = {};

    if (!currentPlayer) {
      return fields;
    }

    const player = currentPlayer;
    const mediaItem = player.current();
    const source = player.currentSource();

    Object.assign(fields, {
      [DIMENSIONS.adapterName]: player.adapterName(),
      [METRICS.currentTime]: player.currentTime() * 1000,
      [METRICS.duration]: player.duration() * 1000
    });

    if (mediaItem) {
      const CDN: string = dot(dot(mediaItem.akamaiStats).data).cdnName || 'unknown';
      let mediaOrigin: string = dot(mediaItem.gaStats).dimension1 || 'unknown';
      if (/:/.test(mediaOrigin)) {
        mediaOrigin = mediaOrigin.split(':')[0];
      }

      Object.assign(fields, {
        [DIMENSIONS.id]: mediaItem.id,
        [DIMENSIONS.mediaType]: mediaItem.isVideo ? 'video' : 'audio',
        [DIMENSIONS.mediaCategory]: mediaItem.isLive ? 'live' : 'ondemand',
        [DIMENSIONS.title]: mediaItem.title,
        [DIMENSIONS.CDN]: CDN,
        [DIMENSIONS.mediaOrigin]: mediaOrigin
      });
    }

    if (source) {
      Object.assign(fields, {
        [DIMENSIONS.mediaUrl]: source.src,
        [DIMENSIONS.mimeType]: source.type
      });
    }

    const currentQuality = player.getPlaybackQuality();
    if (currentQuality && currentQuality.width) {
      Object.assign(fields, {
        [DIMENSIONS.videoSize]: `${currentQuality.width}x${currentQuality.height}`
      });
    }

    Object.assign(fields, {
      [DIMENSIONS.configKey]: player.get('config') || 'undefined',
      [DIMENSIONS.style]: player.get('style') || 'undefined'
    });

    return fields;
  }

  function sendEvent(eventCategory: string, eventAction: string, eventLabel?: string, eventValue?: number, fields?: Fields): void {
    const playerFields = getPlayerFields();

    tracker.send('event', {
      eventCategory,
      eventAction,
      eventLabel,
      eventValue,
      ...fields,
      ...playerFields
    });
  }

  function sendTiming(timingCategory: string, timingVar: string, timingValue: number, timingLabel?: string, fields?: Fields) {
    const playerFields = getPlayerFields();

    tracker.send('timing', {
      timingCategory,
      timingVar,
      timingValue,
      ...fields,
      ...playerFields
    });
  }

  function trackPlayer(player: ExtendedLudo) {
    currentPlayer = player;

    trackTiming(tracker, player, sendTiming);
    trackErrors(player, sendEvent);
    trackLatestEvent(tracker, player);
    trackMissingGaTrackingId(player, sendEvent);

    player.on('track', sendEvent);
  }

  function trackLink(linkElement: HTMLAnchorElement, category: string, action: string, label?: string) {
    linkElement.addEventListener('click', (event) => {
      sendEvent(category, action, label);

      // If the link opens in the same tab/window, then add a tiny delay for
      // the tracking request to be sent.
      const target = event.target && (event.target as HTMLAnchorElement).target || '_self';
      const modifiers = event.altKey || event.ctrlKey || event.metaKey || event.shiftKey;
      const sameWindow = !modifiers && target !== '_blank';

      if (sameWindow) {
        event.preventDefault();
        window.setTimeout(() => {
          window.open(linkElement.href, target);
        }, 100);
      }
    });
  }

  function trackLinks(player: ExtendedLudo, linkElements: NodeListOf<HTMLAnchorElement>, category: string) {
    const current = player && player.current && player.current();
    const label = current && current.id;

    Array.prototype.forEach.call(linkElements, (link: HTMLAnchorElement) => {
      const action = link.getAttribute('data-action-name');
      if (action) {
        trackLink(link, category, action, ...(label ? [label] : []));
      }
    });
  }

  return {
    sendEvent,
    trackPlayer,
    trackLinks
  };
};
