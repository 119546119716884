import { LudoEvents, LudoMediaTypes } from '@nrk/ludo-core';
import eventToggler from '../../ui/eventToggler';
import UI_EVENTS from '../../ui/events';
import SCRUBBER_EVENTS from './scrubberEvents';
import throttle from 'lodash/throttle';
import { appendEmptyPrograms, mapToLayoutModel } from './epgBuilder';
import NrkEvents from '../../../nrk/NrkEvents';
import { ScrubberContext } from './index';
import { ScrubberPoints } from './vodHandler';
import { EPGProgram } from '../../../nrk/LiveEpg/types';

interface ProgramPartCounter {
  [index: string]: number;
}

export default ({ player, ui, scrubberEvents, progressBarElement }: ScrubberContext) => {

  let cachedEpg: EPGProgram[] = [];
  let duration = 0;

  function updatePoints({ updated = false } = {}) {
    const progressBarWidth = progressBarElement.offsetWidth || 0;
    const currentTime = player.currentTime();
    duration = player.duration();

    const programs = appendEmptyPrograms(cachedEpg, duration)
      .map((program, index, orderedEpg) => {
        let actualEnd = program.actualEndUTC;

        const nextProgram = orderedEpg[index + 1];
        if (nextProgram) {
          actualEnd = Math.min(actualEnd, nextProgram.actualStartUTC);
        }

        const startPosition = player.convertLiveTimeToTime(new Date(program.actualStartUTC));
        const endPosition = player.convertLiveTimeToTime(new Date(actualEnd));

        return {
          ...program,
          startPosition,
          endPosition
        };
      });

    const scrubberItems = mapToLayoutModel(programs, currentTime, duration, progressBarWidth);

    scrubberEvents.emit(SCRUBBER_EVENTS.EPG_UPDATED, scrubberItems, { updated });

    // A program can be split in two by another program (e.g. news). The parts
    // must be treated as separate programs for certain scrubber functionality
    // to work (see LUDO-533). Thus this counter:
    const programPartCounter: ProgramPartCounter = {};

    const scrubberPoints: ScrubberPoints = scrubberItems.reduce((o, program) => {
      const {
        startPosition: position,
        leftPc,
        leftPx,
        title,
        programId
      } = program;

      if (!programPartCounter[programId]) {
        programPartCounter[programId] = 1;
      } else {
        programPartCounter[programId]++;
      }

      const programPartId = `${programId}_${programPartCounter[programId]}`;

      o[programPartId] = {
        position,
        leftPc,
        leftPx,
        title
      };
      return o;
    }, {} as ScrubberPoints);

    scrubberEvents.emit(SCRUBBER_EVENTS.POINTS_UPDATED, {
      type: 'epg',
      points: scrubberPoints,
      updated
    });
  }

  function epgUpdated(epg: EPGProgram[]) {
    cachedEpg = epg;

    updatePoints({ updated: true });
  }

  const throttledUpdatePoints = throttle(updatePoints, 1000, { trailing: false });

  const playerToggler = eventToggler(player, {
    [NrkEvents.EPG_UPDATED]: epgUpdated,
    [LudoEvents.SEEKED]: () => updatePoints({ updated: true })
  });

  const scrubberToggler = eventToggler(scrubberEvents, {
    [SCRUBBER_EVENTS.ENDTIME_SECOND_UPDATED]: throttledUpdatePoints
  });

  const uiToggler = eventToggler(ui, {
    [UI_EVENTS.PLAYER_SIZE_CHANGED]: throttledUpdatePoints
  });

  function bind() {
    playerToggler.on();
    scrubberToggler.on();
    uiToggler.on();
  }

  function unbind() {
    playerToggler.off();
    scrubberToggler.off();
    uiToggler.off();
  }

  player.on(LudoEvents.LOADED, () => {
    if (player.mediaType() === LudoMediaTypes.DVR) {
      bind();
      epgUpdated([]);
    } else {
      unbind();
    }
  });

};
