// Time zone offsets for Norway.
const TimeOffset = {
  standardTime: 60,
  summerTime: 120
};

// Applies to all European countries which have summer time, including Norway.
const SummerTime: Record<number, { beginUTC: number, endUTC: number }> = {
  2019: {
    beginUTC: new Date(2019, 3, 31, 1, 0, 0).getTime(),
    endUTC: new Date(2019, 10, 27, 1, 0, 0).getTime()
  },
  2020: {
    beginUTC: new Date(2020, 3, 29, 1, 0, 0).getTime(),
    endUTC: new Date(2020, 10, 25, 1, 0, 0).getTime()
  },
  2021: {
    beginUTC: new Date(2021, 3, 28, 1, 0, 0).getTime(),
    endUTC: new Date(2021, 10, 31, 1, 0, 0).getTime()
  },
  2022: {
    beginUTC: new Date(2022, 3, 27, 1, 0, 0).getTime(),
    endUTC: new Date(2022, 10, 30, 1, 0, 0).getTime()
  },
  2023: {
    beginUTC: new Date(2023, 3, 26, 1, 0, 0).getTime(),
    endUTC: new Date(2023, 10, 29, 1, 0, 0).getTime()
  },
  2024: {
    beginUTC: new Date(2024, 3, 31, 1, 0, 0).getTime(),
    endUTC: new Date(2024, 10, 27, 1, 0, 0).getTime()
  },
  2025: {
    beginUTC: new Date(2025, 3, 30, 1, 0, 0).getTime(),
    endUTC: new Date(2025, 10, 26, 1, 0, 0).getTime()
  }
};

export function getTimeZoneOffset(timeUTC: number): number {
  const year = new Date(timeUTC).getUTCFullYear();

  if (!(year in SummerTime)) {
    return TimeOffset.standardTime;
  }
  if (timeUTC < SummerTime[year].beginUTC || timeUTC >= SummerTime[year].endUTC) {
    return TimeOffset.standardTime;
  }
  return TimeOffset.summerTime;
}
