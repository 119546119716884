import bows from 'bows';
import EventEmitter from 'eventemitter3';
import { LudoEvents } from '@nrk/ludo-core';
import NrkEvents from '../NrkEvents';
import { DIMENSION } from './tracker';

import importedVendorSpringstreams from '@nrk/media-analytics/dist/vendor/scores/springstreams';
import {
  Events as AnalyticsEvents,
  mediaTracker as importedMediaTracker,
  Types as AnalyticsTypes,
  VendorConfig
} from '@nrk/media-analytics';
import { ExtendedLudo } from '../../ludo/interfaces';

type EPGProgram = import('../LiveEpg/types').EPGProgram;

function getAnalyticsEmitter(player: ExtendedLudo) {
  const emitter = new EventEmitter();
  let isAttacheded = false;

  function forwardEvent(analyticsEvent: string) {
    return (...args: any[]) => emitter.emit(analyticsEvent, ...args);
  }

  let itemChanged = false;

  const ludoToAnalyticsEventMapping = {
    [LudoEvents.PREPARATORSCOMPLETE]: forwardEvent(AnalyticsEvents.INTENT_TO_PLAY),
    [LudoEvents.PLAYING]: forwardEvent(AnalyticsEvents.PLAYING),
    [LudoEvents.PAUSE]: forwardEvent(AnalyticsEvents.PAUSE),
    [LudoEvents.SEEKED]: forwardEvent(AnalyticsEvents.SEEKED),
    [LudoEvents.SEEKING]: forwardEvent(AnalyticsEvents.SEEKING),
    [LudoEvents.BUFFERSTART]: forwardEvent(AnalyticsEvents.BUFFERSTART),
    [LudoEvents.BUFFEREND]: forwardEvent(AnalyticsEvents.BUFFEREND),
    [LudoEvents.ERROR]: (event: string, data: any) => {
      if (data.fatal) {
        emitter.emit(AnalyticsEvents.ERROR);
      }
    },
    [LudoEvents.ENDED]: (...args: any[]) => {
      forwardEvent(AnalyticsEvents.ENDED)(...args);
      player.once(LudoEvents.PLAY, () => {
        emitter.emit(AnalyticsEvents.INTENT_TO_PLAY);
      });
    },
    [LudoEvents.ITEM_CHANGED]: () => {
      // Do not emit unload for first item
      if (!itemChanged) {
        itemChanged = true;
        return;
      }
      emitter.emit(AnalyticsEvents.UNLOAD);
      player.once(LudoEvents.PREPARED, () => {
        emitter.emit(AnalyticsEvents.INTENT_TO_PLAY);
      });
    },
    [LudoEvents.UNLOADED]: () => {
      emitter.emit(AnalyticsEvents.UNLOAD);
    },
    [LudoEvents.QUALITY_SWITCHED]: (quality: any) => {
      const { bitrate } = quality;
      if (bitrate) {
        emitter.emit(AnalyticsEvents.BITRATESWITCH, bitrate);
      }
    },
    [NrkEvents.LIVEPROGRAM_CHANGED]: (data: { programId: string; } | null) => {
      emitter.emit(AnalyticsEvents.PROGRAM_CHANGED, data ? data.programId : null);
    }
  };

  function attachForwardEvents() {
    if (isAttacheded) {
      return;
    }
    Object.keys(ludoToAnalyticsEventMapping).forEach((ludoEvent) => {
      const forwardFunc = ludoToAnalyticsEventMapping[ludoEvent];
      player.on(ludoEvent, forwardFunc);
    });
    isAttacheded = true;
  }

  function detachForwardEvents() {
    if (!isAttacheded) {
      return;
    }
    Object.keys(ludoToAnalyticsEventMapping).forEach((ludoEvent) => {
      const forwardFunc = ludoToAnalyticsEventMapping[ludoEvent];
      player.off(ludoEvent, forwardFunc);
    });
    isAttacheded = false;
  }

  return {
    emitter,
    attachForwardEvents,
    detachForwardEvents
  };
}

export default function nrkMediaLogger(player: ExtendedLudo, container: any = {}) {
  const {
    tracker,
    debug = bows,
    SpringStreams = importedVendorSpringstreams,
    mediaTracker = importedMediaTracker
  } = container;

  const akamaiLog = debug('akamai');

  // vendors
  const vendors = {
    [AnalyticsTypes.SCORES]: SpringStreams,
    [AnalyticsTypes.AKAMAI]: (...args: any[]) => akamaiLog(args),
    [AnalyticsTypes.GA]: (data: any) => tracker.send('event.personalization', data)
  };

  // emitter
  const {
    emitter,
    attachForwardEvents,
    detachForwardEvents
  } = getAnalyticsEmitter(player);

  // loggers
  const loggers: {
    [AnalyticsTypes.SCORES]?: any;
    [AnalyticsTypes.AKAMAI]?: any;
    [AnalyticsTypes.GA]?: any;
  } = {};

  Object.keys(AnalyticsTypes)
    .map((key) => key as keyof typeof AnalyticsTypes)
    .forEach((t) => {
      loggers[AnalyticsTypes[t]] = debug(`ludo:mTr:${t.toLowerCase()}`);
    });

  // data
  const data: {
    [AnalyticsTypes.SCORES]?: any;
    [AnalyticsTypes.AKAMAI]?: any;
    [AnalyticsTypes.GA]?: any;
  } = {};

  let isLive: boolean;

  Object.keys(AnalyticsTypes)
    .map((key) => key as keyof typeof AnalyticsTypes)
    .forEach((t) => {
      data[AnalyticsTypes[t]] = {};
    });

  // Put the current adapter name in the tracker configurations.
  const updateAdapterName = () => {
    if (data[AnalyticsTypes.AKAMAI] && data[AnalyticsTypes.AKAMAI].data) {
      const adapterName = player.adapterName() || 'LudoUnresolvedAdapter';
      data[AnalyticsTypes.AKAMAI].data.playerId = adapterName;
    }
  };

  player.on(LudoEvents.PREPARED, () => {
    const mediaItem = player.current()!;
    if (mediaItem.requireManifestLoad) {
      return;
    }

    data[AnalyticsTypes.SCORES] = mediaItem.scoresStats;
    const src = mediaItem.sources && mediaItem.sources.length && mediaItem.sources[0].src;
    const akamaiStats = {
      src,
      ...mediaItem.akamaiStats
    };
    data[AnalyticsTypes.AKAMAI] = akamaiStats;
    data[AnalyticsTypes.GA] = tracker.getFields();

    updateAdapterName();

    isLive = mediaItem.isLive;

    emitter.emit(AnalyticsEvents.DATA_CHANGED);
  });

  player.on(LudoEvents.LOADED, () => {
    const mediaItem = player.current()!;

    // For channels, delay emitting loaded event to media-tracker until first
    // liveprogram-changed event. The springStreamProgramId must be set before
    // LOADED is emitted.
    if (mediaItem.isChannel) {
      let timeoutId: number | undefined;

      const emitAfterEvent = (program?: EPGProgram) => {
        window.clearTimeout(timeoutId);
        timeoutId = undefined;
        emitLoadedOnLiveProgramChanged(program);
      };

      const emitAfterTimeout = () => {
        player.off(NrkEvents.LIVEPROGRAM_CHANGED, emitAfterEvent);
        timeoutId = undefined;
        emitLoadedOnLiveProgramChanged();
      };

      timeoutId = window.setTimeout(emitAfterTimeout, 5000);
      player.once(NrkEvents.LIVEPROGRAM_CHANGED, emitAfterEvent);

      return;
    }

    // OnDemand streams need the duration ready before media-analytics can be
    // LOADED. The quantile tracking depends on the duration.
    if (!mediaItem.isLive && !player.duration()) {
      const emitOnDuration = (duration: number) => {
        if (duration) {
          player.off(LudoEvents.DURATIONCHANGE, emitOnDuration);
          emitLoaded();
        }
      };

      player.on(LudoEvents.DURATIONCHANGE, emitOnDuration);
      return;
    }

    emitter.emit(AnalyticsEvents.LOADED);
  });

  function emitLoadedOnLiveProgramChanged(program?: EPGProgram) {
    const mediaItem = player.current()!;

    data[AnalyticsTypes.SCORES] = {
      ...mediaItem.scoresStats,
      springStreamProgramId: program ? program.programId : null
    };

    // HACK: mutate gaFields-object stored by gaTracker
    // so that dimension.id is reported for all events (missing for STARTED event)
    const programId = program ? `prf:${program.programId}` : null;
    const gaFields = tracker.getFields();
    gaFields[DIMENSION.id] = programId;

    emitter.emit(AnalyticsEvents.DATA_CHANGED);
    emitLoaded();
  }

  /*
   * Emit LOADED, and if already playing, emit PLAYING as well. For use when
   * the LOADED event needs to wait for something else to happen first (see
   * the LOADED event handler above), and is now ready to be emitted. The
   * PLAYING event might have already happened, and so needs to be emitted too.
   *
   * For example, the Samsung Internet browser provides the duration of
   * on-demand streams in the DURATIONCHANGE event that follows the PLAYING
   * event. So for the duration to be used by media-analytics, LOADED must await
   * the DURATIONCHANGE event that provides the duration, and then playing
   * actually starts and can be emitted as well.
   */
  function emitLoaded() {
    emitter.emit(AnalyticsEvents.LOADED);

    if (!player.isPaused()) {
      emitter.emit(AnalyticsEvents.PLAYING);
    }
  }

  // config
  const getConfig = (type: typeof AnalyticsTypes[keyof typeof AnalyticsTypes]): VendorConfig => {
    return {
      playerName: 'NRK Ludo player',
      playerVersion: player.get('version'),
      getLogger: () => loggers[type],
      getVendor: () => vendors[type],
      getData: () => data[type],
      getChannelId: () => player.current()?.isChannel ? player.current()?.id : undefined
    };
  };

  function getPosition() {
    if (isLive) {
      return player.currentLiveTime().getTime() / 1000;
    }
    return player.currentTime();
  }

  function getDuration() {
    if (isLive) {
      return player.convertTimeToLiveTime(player.duration()).getTime() / 1000;
    }
    return player.duration();
  }

  // adapter
  const adapter = {
    getPosition,
    getDuration,
    getConfig
  };

  mediaTracker(window, emitter, adapter);

  player.on(LudoEvents.ADAPTER, () => {
    const adapterName = player.adapterName();
    if (adapterName === 'LudoCastPlayerAdapter') {
      detachForwardEvents();
      emitter.removeAllListeners();
    }
  });

  attachForwardEvents();
}
