import { LudoBufferTypes, LudoMediaItem } from '@nrk/ludo-core';
import { parse, toSeconds } from 'iso8601-duration';
import { dot } from '../../utilities/dot';

type PodcastEpisode = import('../../APIClient/psapi/response/PodcastEpisode').PodcastEpisode;

export default class PodcastMediaItem implements LudoMediaItem {
  startTime?: number;
  private data: PodcastEpisode;

  constructor(data: PodcastEpisode, startTime: number = 0) {
    this.data = data;
    this.startTime = startTime;
  }

  get sources(): { type: string, src: string }[] {
    return this.data.downloadables.map((downloadable: any) => {
      const { audio, video } = downloadable;
      const items = [audio, video].filter((x) => x);

      if (!items.length) {
        return null;
      }

      const {
        mimeType,
        url
      } = items[0];

      return {
        type: mimeType,
        src: url
      };
    })
      .filter((x: any) => x) as { type: string, src: string }[];
  }

  get poster() {
    let imageUrl: string | null | undefined = this.data.imageUrl;
    if (!imageUrl || !imageUrl.length) {
      imageUrl = dot(dot(this.data._embedded).podcast).imageUrl;
    }
    return imageUrl ? imageUrl.replace(/http:/i, '') : '';
  }

  get showTitle() {
    return dot(dot(dot(this.data._embedded).podcast).titles).title;
  }

  get isVideo(): boolean {
    return false;
  }

  get isAudio(): boolean {
    return true;
  }

  get id(): string {
    const selfLink = this.data._links.self || this.data._links.podcastEpisode;
    return dot(selfLink).href || this.sources[0].src;
  }

  get isPlayable() {
    return this.sources.length > 0;
  }

  get title(): string {
    return this.data.titles.title;
  }

  get isLive() {
    return false;
  }

  get subtitle(): string {
    return this.data.titles.subtitle;
  }

  get duration() {
    const d = this.data.duration;
    return d ? toSeconds(parse(d)) : 0;
  }

  get bufferDuration() {
    return this.duration;
  }

  get scoresStats() {
    return null;
  }

  get gaStats() {
    return null;
  }

  get akamaiStats() {
    return null;
  }

  get availableFrom() {
    const from = this.data.publishedAt;
    return from ? new Date(from) : null;
  }

  get type() {
    return this.sources.length ? this.sources[0].type : '';
  }

  get bufferType() {
    return LudoBufferTypes.FIXED; // On demand
  }

  get isChannel() {
    return false;
  }

  get isOngoing() {
    return false;
  }

  get mimeTypes() {
    return this.sources.map((source) => source.type);
  }
}
